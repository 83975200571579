import React, { useEffect, useState } from "react";
import "./Video-Library.scss";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import useAuth from "../../hooks/useAuth";
import { useLanguage } from "../../contexts/LanguageContext";

export default function VideoLibrary() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();
  const { language } = useLanguage();

  const content = {
    ar: {
      title: "مكتبة الفيديوهات",
      loading: "جاري تحميل الفيديوهات...",
      price: "السعر:",
      purchased: "مشتري",
      creationDate: "تاريخ الإنشاء:",
      buyButton: "اشتري هذا الفيديو",
      loginAlert: "يرجى تسجيل الدخول لشراء الفيديو.",
      successTitle: "تم إرسال الكوبون بنجاح!",
      successMessage: "لقد تم تطبيق كود الكوبون بنجاح. شكرًا لك على استخدامك.",
      modalTitle: "أدخل الكوبون",
      couponPlaceholder: "أدخل كود الكوبون",
      cancelButton: "إلغاء",
      confirmButton: "تأكيد",
      invalidCoupon: "يرجى إدخال كوبون صالح.",
      usedCoupon: "لقد تم استخدام هذا الكوبون بالفعل",
      errorFetching: "حدث خطأ أثناء جلب البيانات.",
      problemOccurred: "هناك مشكلة:",
    },
    en: {
      title: "Video Library",
      loading: "Loading videos...",
      price: "Price:",
      purchased: "Purchased",
      creationDate: "Creation Date:",
      buyButton: "Buy this video",
      loginAlert: "Please log in to purchase the video.",
      successTitle: "Coupon sent successfully!",
      successMessage:
        "The coupon code has been applied successfully. Thank you for using it.",
      modalTitle: "Enter Coupon",
      couponPlaceholder: "Enter coupon code",
      cancelButton: "Cancel",
      confirmButton: "Confirm",
      invalidCoupon: "Please enter a valid coupon.",
      usedCoupon: "This coupon has already been used",
      errorFetching: "An error occurred while fetching data.",
      problemOccurred: "A problem occurred:",
    },
  };

  const currentContent = content[language];
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          "https://admin.internationalskillsacademy.com/api/video_library/"
        );
        if (!response.ok) {
          throw new Error(currentContent.errorFetching);
        }
        const data = await response.json();

        const purchasedVideos =
          JSON.parse(localStorage.getItem("purchasedVideos")) || [];

        const videosWithPurchaseInfo = data.results.map((video) => ({
          ...video,
          isPurchased: purchasedVideos.includes(video.id),
        }));
        setVideos(videosWithPurchaseInfo);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [currentContent.errorFetching]);

  const onSubmit = (data) => {
    const couponRegex = /^[a-zA-Z0-9-]{36}$/;

    if (!couponRegex.test(couponCode)) {
      alert(currentContent.invalidCoupon);
      return;
    }

    const couponData = { coupon_code: couponCode };

    fetch(
      `https://admin.internationalskillsacademy.com/api/video_library/${selectedVideo.id}/enroll/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user.token}`,
        },
        body: JSON.stringify(couponData),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json().then((data) => {
            setVideos((prevVideos) =>
              prevVideos.map((video) =>
                video.id === selectedVideo.id
                  ? { ...video, isPurchased: true }
                  : video
              )
            );
            setShowSuccessMessage(true);
            setCouponCode("");
            setShowCouponModal(false);
          });
        }
        return res.json().then((data) => {
          alert(data.message || currentContent.usedCoupon);
        });
      })
      .catch((error) => {
        console.error(currentContent.problemOccurred, error);
        setErrorMessage(error.message);
      });
  };

  if (loading) return <div>{currentContent.loading}</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="video-library">
      <h2>{currentContent.title}</h2>
      <div className="container">
        <div className="video-list">
          {videos.map((video) => (
            <div className="video-item" key={video.id}>
              <img
                src={video.image}
                alt={video.title}
                className="video-thumbnail"
              />
              <h4
                style={{
                  marginTop: "10px",
                }}
              >
                {video.title}
              </h4>
              {video.isPurchased ? (
                <>
                  <p>
                    {currentContent.price}{" "}
                    <span style={{ color: "green" }}>
                      {currentContent.purchased}
                    </span>
                  </p>
                  <video controls>
                    <source src={video.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </>
              ) : (
                <>
                  <p>
                    {currentContent.price} {video.price} SP
                  </p>
                  <p>
                    {currentContent.creationDate}{" "}
                    {new Date(video.date_created).toLocaleDateString()}
                  </p>
                  <button
                    className="btn-buy"
                    onClick={() => {
                      if (!user) {
                        alert(currentContent.loginAlert);
                        return;
                      }
                      setSelectedVideo(video);
                      setShowCouponModal(true);
                    }}
                  >
                    {currentContent.buyButton}
                  </button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {showSuccessMessage && (
        <Alert
          style={{ position: "absolute", top: "100px", right: 0, left: 0 }}
          variant="success"
          onClose={() => setShowSuccessMessage(false)}
          dismissible
        >
          <Alert.Heading>{currentContent.successTitle}</Alert.Heading>
          <p>{currentContent.successMessage}</p>
        </Alert>
      )}

      <Modal show={showCouponModal} onHide={() => setShowCouponModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentContent.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder={currentContent.couponPlaceholder}
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowCouponModal(false)}>
            {currentContent.cancelButton}
          </Button>
          <Button
            style={{ backgroundColor: "#2f2d52", border: "1px solid #2f2d52" }}
            onClick={onSubmit}
          >
            {currentContent.confirmButton}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
