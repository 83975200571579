import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./CategoryPage.scss";
import { useLanguage } from "../../contexts/LanguageContext";

const useCategoryData = (slug) => {
  const { language } = useLanguage();
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [courses, setCourses] = useState([]);
  const [subCategoryCourses, setSubCategoryCourses] = useState([]);

  useEffect(() => {
    const fetchCategoryAndCourses = async () => {
      try {
        const categoryResponse = await axios.get(
          `https://admin.internationalskillsacademy.com/api/categories?lang=${language}`
        );
        const categoryData = categoryResponse.data.results.find(
          (cat) => cat.slug === slug
        );

        if (categoryData) {
          setCategory(categoryData);

          const coursesResponse = await axios.get(
            `https://admin.internationalskillsacademy.com/api/courses/?categories__id=${categoryData.id}&lang=${language}`
          );
          setCourses(coursesResponse.data.results);
        } else {
          throw new Error("Category not found");
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryAndCourses();
  }, [slug, language]);

  const fetchSubCategoryCourses = async (subCategoryId) => {
    try {
      const response = await axios.get(
        `https://admin.internationalskillsacademy.com/api/courses/?sub_categories__id=${subCategoryId}&lang=${language}`
      );
      setSubCategoryCourses(response.data.results);
    } catch (err) {
      setError(err);
    }
  };

  return {
    category,
    loading,
    error,
    courses,
    subCategoryCourses,
    fetchSubCategoryCourses,
  };
};

const CourseSummary = ({ summary }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 3;

  const summaryLines = summary.split("\n");
  const isLong = summaryLines.length > maxLength;

  const displayedSummary = isExpanded
    ? summary
    : summaryLines.slice(0, maxLength).join("\n");

  return (
    <div>
      <p>{displayedSummary}</p>
      {isLong && (
        <a onClick={() => setIsExpanded(!isExpanded)} className="read-more">
          {isExpanded ? "أقرأ أقل " : "أقرأ المزيد"}
        </a>
      )}
    </div>
  );
};

const CategoryPage = () => {
  const { slug } = useParams();
  const { language } = useLanguage();
  const {
    category,
    loading,
    error,
    courses,
    subCategoryCourses,
    fetchSubCategoryCourses,
  } = useCategoryData(slug);

  const content = {
    ar: {
      noSubCategories: "لا توجد فئات فرعية في هذه الفئة.",
      noCourses: "لا توجد دورات متعلقة في هذه الفئة.",
    },
    en: {
      noSubCategories: "There are no subcategories in this category.",
      noCourses: "There are no related courses in this category.",
    },
  };

  const currentContent = content[language];

  if (loading) return <div className="loading">Loading...</div>;
  if (error)
    return (
      <div className="error">Error fetching category: {error.message}</div>
    );

  return (
    <>
      <img
        className="img-category-page"
        src={category.image}
        alt={category.title}
      />
      <div className="category-page">
        <h1 className="category-title">{category.title}</h1>
        <p className="category-description">{category.short_description}</p>

        <h2>
          {language === "ar" ? "أقسام التدريب الفرعية:" : "Subcategories:"}
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            flexWrap: "wrap",
            marginTop: "20px",
          }}
          className="subcategories-container"
        >
          {category.subCategories && category.subCategories.length > 0 ? (
            category.subCategories.map((subCategory) => (
              <div key={subCategory.id}>
                <button
                  className="btn-category-page"
                  onClick={() => fetchSubCategoryCourses(subCategory.id)}
                >
                  {subCategory.title}
                </button>
              </div>
            ))
          ) : (
            <div>{currentContent.noSubCategories}</div>
          )}
        </div>

        <h2>{language === "ar" ? "الدورات المتعلقة:" : "Related Courses:"}</h2>
        <div className="courses-container">
          {(subCategoryCourses.length > 0 ? subCategoryCourses : courses).map(
            (course) => (
              <Link
                to={`/course/${course.id}`}
                className="course-card"
                key={course.id}
              >
                <h3>{course.title}</h3>
                <img src={course.image} alt={course.title} />
                <CourseSummary summary={course.summary} />
              </Link>
            )
          )}

          {subCategoryCourses.length === 0 && courses.length === 0 && (
            <div className="no-courses">{currentContent.noCourses}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryPage;
