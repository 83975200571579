import "./CustomerService.scss";
import { Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";

export default function CustomerService() {
  const { language } = useLanguage();

  const content = {
    ar: {
      title: "الشكاوي و خدمة العملاء",
      description:
        "إذا كان لديك أسئلة أو اقتراحات، يرجى ملء نموذج ملاحظاتك أو اقتراحاتك أو استفساراتك",
      clickHere: "بالضغط هنا",
      address: "يمكنكم مراسلتنا على العنوان التالي:",
      addressDetails:
        "المنشية، خلف بناء المحافظة/ طرطوس، الجمهورية العربية السورية.",
      emailContact: "ويمكنكم الاتصال بنا عبر البريد الإلكتروني:",
      contactMethods: "ويمكنكم الاتصال بنا عن طريق:",
      phone: "الهاتف:",
      email: "البريد الإلكتروني:",
    },
    en: {
      title: "Complaints and Customer Service",
      description:
        "If you have any questions or suggestions, please fill out your feedback, suggestions, or inquiries form",
      clickHere: "by clicking here",
      address: "You can contact us at the following address:",
      addressDetails:
        "Al-Manshiyah, behind the Governorate building / Tartous, Syrian Arab Republic.",
      emailContact: "You can contact us via email:",
      contactMethods: "You can contact us through:",
      phone: "Phone:",
      email: "Email:",
    },
  };

  const currentContent = content[language];

  return (
    <div
      className="custom-services"
      style={{ paddingTop: "220px", padding: "50px" }}
    >
      <div className="overlay"></div>
      <h2>{currentContent.title}</h2>

      <p>
        {currentContent.description}{" "}
        <Link to="/contact">{currentContent.clickHere}</Link>
        <br />
        {currentContent.address}
        <strong>{currentContent.addressDetails}</strong>
        <br /> {currentContent.emailContact}{" "}
        <a href="mailto:isaacademy023@gmail.com">isaacademy023@gmail.com</a>
        <br />
        {currentContent.contactMethods}
      </p>

      <ul>
        <li>
          {" "}
          {currentContent.phone}{" "}
          <a href="tel:+963988954443">+ 963 988 954 443</a>
        </li>
        <li>
          {" "}
          {currentContent.email}{" "}
          <a href="mailto:isaacademy023@gmail.com">isaacademy023@gmail.com</a>
        </li>
      </ul>
    </div>
  );
}
