import { useState, useEffect } from "react";
import axios from "axios";

const useAuth = () => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  const handleLoginUser = async (data, location, history) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `https://admin.internationalskillsacademy.com/api/auth/`,
        {
          username: data.email,
          password: data.password,
        }
      );
      setUser(response.data);
      history.push(location.state?.from || "/");
    } catch (err) {
      setError(err.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateUser = async (data, location, history) => {
    const { username, password, email, firstName, lastName } = data;

    try {
      const userData = {
        username: username,
        password: password,
        password2: password,
        email: email,
        first_name: firstName,
        last_name: lastName,
      };

      const response = await fetch(
        `https://admin.internationalskillsacademy.com/api/register/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Failed to create user!");
      }

      const user = await response.json();
      setUser(user);
      history.push("/login");
    } catch (error) {
      setError(error.message);
    }
  };

  const logOut = () => {
    setUser(null);
  };

  return {
    user,
    handleLoginUser,
    error,
    isLoading,
    handleCreateUser,
    setUser,
    logOut,
  };
};

export default useAuth;
