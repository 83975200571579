import { useEffect, useState } from "react";
import { useLanguage } from "../contexts/LanguageContext";

const useCourses = () => {
  const [courses, setCourses] = useState({ results: [] });
  const { language } = useLanguage();

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await fetch(
        `https://admin.internationalskillsacademy.com/api/courses/?lang=${language}`
      );
      const data = await response.json();

      setCourses((prevCourses) => {
        const existingIds = new Set(
          prevCourses.results.map((course) => course.id)
        );

        const newCourses = data.results.filter(
          (course) => !existingIds.has(course.id)
        );
        return { results: [...prevCourses.results, ...newCourses] };
      });
    };

    fetchCourses();
  }, [language]);

  return [courses];
};

export default useCourses;
