// import React from "react";
// import "./HomeBanner.scss";
// import homeBg from "../../images/illustration-4.webp";
// import homeVideo from "../../images/test 3.mp4";
// import Advertisement from "../Advertisement/Advertisement";

// const Home = () => {
//   return (
//     <>
//       <Advertisement />

//       <div className="home">
//         <div>
//           <video className="video-overlay" autoPlay loop muted>
//             <source src={homeVideo} type="video/mp4" />
//           </video>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;

import React, { useEffect, useState } from "react";
import "./HomeBanner.scss";
import homeBg from "../../images/illustration-4.webp";
import Advertisement from "../Advertisement/Advertisement";
import axios from "axios";

const Home = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          "https://admin.internationalskillsacademy.com/api/header_slider/"
        );
        setVideos(response.data.results);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching the videos:", error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <>
      <Advertisement />

      <div className="home">
        {loading ? (
          <div>Loading...</div>
        ) : (
          videos.map((videoData) => (
            <div key={videoData.id}>
              <video className="video-overlay" autoPlay loop muted>
                <source src={videoData.video} type="video/mp4" />
              </video>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Home;
