import React, { useEffect, useState } from "react";
import "./CHL-Page.scss";
import { useLanguage } from "../../contexts/LanguageContext";

export default function CHLPage() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useLanguage();

  const content = {
    ar: {
      loading: "جارٍ التحميل...",
      error: "حدث خطأ: ",
      detailsUnavailable: "غير متاح",
    },
    en: {
      loading: "Loading...",
      error: "Error occurred: ",
      detailsUnavailable: "Not available",
    },
  };

  const currentContent = content[language];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/pages/?lang=${language}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok" + response.statusText);
        }
        const result = await response.json();
        setData(result.results[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [language]);

  if (loading) {
    return <p>{currentContent.loading}</p>;
  }

  if (error) {
    return (
      <p>
        {currentContent.error} {error.message}
      </p>
    );
  }

  return (
    <>
      <img
        style={{ marginTop: "200px", marginBottom: "100px" }}
        className="img"
        src={data.image}
        alt={data.title || currentContent.detailsUnavailable}
      />
      <h1 className="chl-h1">
        {data.title || currentContent.detailsUnavailable}
      </h1>
      <p dangerouslySetInnerHTML={{ __html: data.body }} />
    </>
  );
}
