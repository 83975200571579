import CertificatesSection from "../CertificatesSection/CertificatesSection";
import CHL_Section from "../CHL-Section/CHL-Section";
import CourseSection from "../CourseSection/CourseSection";
import FeatureSection from "../FeatureSection/FeatureSection";
import GetApp from "../GetApp/GetApp";
import HomeBanner from "../HomeBanner/HomeBanner";
import VerificationSection from "../VerificationSection/VerificationSection";

const Main = () => {
  return (
    <div>
      <HomeBanner />
      <FeatureSection />
      <CourseSection />
      <CHL_Section />
      <CertificatesSection />
      <VerificationSection />
      <GetApp />
    </div>
  );
};

export default Main;
