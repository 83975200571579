import React from "react";
import "./Course.scss";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faClock } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const Course = (props) => {
  const {
    id,
    title,
    categories,
    image,
    lessonss,
    price,
    level,
    date_created,
  } = props.course;

  const history = useHistory();

  const handleDetails = (id) => {
    const url = `/course/${id}`;
    history.push(url);
  };

  const category = categories.length > 0 ? categories[0].title : "غير محدد";
  const lessonsCount = lessonss.length;

  return (
    <div onClick={() => handleDetails(id)} className="course-box shadow">
      <img className="img-fluid" src={image} alt={title} />
      <div className="info">
        <p className="category">{category}</p>
        <h6 className="title">{title}</h6>
        {/* <Rating
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          initialRating={0}
          readonly
        /> */}
        <p className="review" style={{ marginTop: "20px" }}>
          {lessonsCount} <span>({lessonsCount} lessons)</span>
        </p>
        <div className="d-flex justify-content-between">
          <span>
            <FontAwesomeIcon className="icon" icon={faBookOpen} />{" "}
            {lessonsCount} lessons
          </span>
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FontAwesomeIcon className="icon" icon={faClock} />
            {categories.map((category) => (
              <div key={category.id}>{category.date_created}</div>
            ))}
          </span>
          <h5 className="price">{price} SP</h5>
        </div>
      </div>
    </div>
  );
};

export default Course;
