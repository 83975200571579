import React, { useEffect, useState } from "react";
import "./GetApp.scss";
import android from "../../images/android.svg";
import ios from "../../images/ios.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FaceBookIcon from "../../images/104498_facebook_icon.svg";
import WhatsAppIcon from "../../images/3225179_app_logo_media_popular_social_icon.svg";
import InstgramIcon from "../../images/1298747_instagram_brand_logo_social media_icon.svg";
import linkedin from "../../images/10464412.png";
import x from "../../images/sl_z_072523_61700_01.jpg";
import youtube from "../../images/icons8-youtube.svg";
import { useLanguage } from "../../contexts/LanguageContext";

const GetApp = () => {
  const [socialLinks, setSocialLinks] = useState({
    facebook: "",
    whatsapp: "",
    insta: "",
    youtube: "",
    linkedin: "",
    x: "",
  });

  const { language } = useLanguage();

  const content = {
    ar: {
      contactUs: "يمكنك التواصل معنا",
      helpText: "نحن هنا لمساعدتك إذا كان لديك أي استفسارات أو تحتاج إلى دعم",
      facebook: "فيسبوك",
      whatsapp: "واتساب",
      instagram: "انستغرام",
      linkedin: "لينكدان",
      x: "إكس",
      youtube: "يوتيوب",
    },
    en: {
      contactUs: "You can contact us",
      helpText:
        "We are here to help you if you have any inquiries or need support",
      facebook: "Facebook",
      whatsapp: "WhatsApp",
      instagram: "Instagram",
      linkedin: "LinkedIn",
      x: "X",
      youtube: "YouTube",
    },
  };

  const currentContent = content[language];

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await fetch(
          "https://admin.internationalskillsacademy.com/api/website_settings/"
        );
        const data = await response.json();

        if (data.results.length > 0) {
          const {
            facebook,
            whatsapp,
            insta,
            youtube,
            linkedin,
            x,
          } = data.results[0];
          setSocialLinks({ facebook, whatsapp, insta, youtube, linkedin, x });
        }
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <div className="get-app-section">
      <div className="container">
        <div className="header-text text-center">
          <h3>{currentContent.contactUs}</h3>
          <p>{currentContent.helpText}</p>
        </div>
        <div className="app-box text-center">
          <div className="row">
            <div className="col-md-4">
              <div className="contact-box">
                <div
                  onClick={() => window.open(socialLinks.facebook)}
                  className="icon"
                >
                  <img src={FaceBookIcon} alt="FaceBook Icon" />
                </div>
                <p>{currentContent.facebook}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-box">
                <div
                  onClick={() =>
                    window.open(
                      `https://wa.me/${socialLinks.whatsapp}`,
                      "_blank"
                    )
                  }
                  className="icon"
                >
                  <img src={WhatsAppIcon} alt="WhatsApp Icon" />
                </div>
                <p>{currentContent.whatsapp}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-box">
                <div
                  onClick={() => window.open(socialLinks.insta)}
                  className="icon"
                >
                  <img src={InstgramIcon} alt="Instagram Icon" />
                </div>
                <p>{currentContent.instagram}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="contact-box">
                <div
                  onClick={() => window.open(socialLinks.linkedin)}
                  className="icon"
                >
                  <img src={linkedin} alt="LinkedIn Icon" />
                </div>
                <p>{currentContent.linkedin}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-box">
                <div
                  onClick={() => window.open(socialLinks.x)}
                  className="icon"
                >
                  <img src={x} alt="X Icon" />
                </div>
                <p>{currentContent.x}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-box">
                <div
                  onClick={() => window.open(socialLinks.youtube)}
                  className="icon"
                >
                  <img src={youtube} alt="YouTube Icon" />
                </div>
                <p>{currentContent.youtube}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetApp;
