import React, { useState, useEffect } from "react";
import "./CHL-Section.scss";
import logo from "../../images/CHL-Logo.jpg";
import { Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";

const CHL_Section = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const { language } = useLanguage();

  const content = {
    ar: {
      header: "أكاديمية CHL للمونتيسوري",
      description: "هي أكاديمية منبثقة من أكاديمية ISA",
      popupTitle: "خدماتنا الاستشارية",
      popupDescription: "نقدم لكم خدمة الاستشارات الأسرية و التربوية",
      services: [
        "المشكلات الدراسية (التركيز - الانتباه - ضعف التحصيل الدراسي)",
        "المشكلات السيكلوجية (العصبية - العناد - الحساسية - وغيرها)",
        "صعوبات التعلم",
        "تعديل السلوك",
        "الإرشاد الأسري (للأمهات والآباء) من خلال جلسات البيلوثرابيا",
      ],
      contactInfo: "لحجز المواعيد والاستفسار عن الجلسات: ",
      contactNumber: "963933954480+",
      closeButton: "إغلاق",
    },
    en: {
      header: "CHL Montessori Academy",
      description: "An academy derived from ISA Academy",
      popupTitle: "Our Consulting Services",
      popupDescription: "We offer family and educational consulting services",
      services: [
        "Study problems (focus - attention - poor academic performance)",
        "Psychological problems (nervousness - stubbornness - sensitivity - etc.)",
        "Learning difficulties",
        "Behavior modification",
        "Family counseling (for mothers and fathers) through biotherapy sessions",
      ],
      contactInfo: "To book appointments and inquire about sessions: ",
      contactNumber: "+963933954480",
      closeButton: "Close",
    },
  };

  const currentContent = content[language];

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/pages/?lang=${language}`
        );
        const data = await response.json();

        const image = data.results[1]?.image;
        if (image) {
          setImageSrc(image);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [language]);

  return (
    <div className="chl-section">
      <div className="container">
        <div className="header-text text-center">
          <h3>{currentContent.header}</h3>
          <p>{currentContent.description}</p>
        </div>
      </div>
      <div>
        {imageSrc && (
          <Link to="/chl-page">
            <img
              src={imageSrc}
              alt="partner"
              className="partner-logo"
              style={{ cursor: "pointer" }}
            />
          </Link>
        )}
      </div>

      {isPopupOpen && (
        <div className="popup-overlay" onClick={togglePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h4>{currentContent.popupTitle}</h4>
            <p>{currentContent.popupDescription}</p>
            <ul>
              {currentContent.services.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
            <p>
              {currentContent.contactInfo}
              <strong>{currentContent.contactNumber}</strong>
            </p>
            <button onClick={togglePopup}>{currentContent.closeButton}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CHL_Section;
