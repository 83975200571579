import React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import googleIcon from "../../images/google1.png";
import "./LoginRegister.scss";
import useAuth from "../../hooks/useAuth";

const Login = () => {
  const {
    setUser,
    handleGoogleSignIn,
    handleLoginUser,
    error,
    isLoading,
    user,
  } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const history = useHistory();

  const onSubmit = (data) => {
    handleLoginUser(data, location, history);
    console.log(data, location, history);
    reset();
  };

  const signInWithGoogle = () => {
    handleGoogleSignIn(location, history);
  };

  return (
    <div className="login-section">
      <div className="container">
        <div className="row">
          <div className="col">
            {error && <p className="failure-alert">{error}</p>}
            {user && <p className="success-alert">Logged in successfully</p>}
            <div className="form-box">
              <h4>تسجيل الدخول</h4>
              {/* <button
                onClick={signInWithGoogle}
                className="btn-black google-btn shadow-sm"
              >
                <img src={googleIcon} alt="" /> Sign in With Google
              </button> */}
              <div className="divider">
                {/* <span>or Sign in with Email</span> */}
                <span> تسجيل الدخول عبر الايميل</span>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-0">
                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor="">اسم المستخدم</label>
                    <input
                      className="form-control"
                      defaultValue=""
                      {...register("email", { required: true })}
                      placeholder="اسم المستخدم"
                    />
                    {errors.email && (
                      <span className="error">email is required</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="">كلمة السر</label>
                    <input
                      className="form-control"
                      defaultValue=""
                      {...register("password", {
                        required: true,
                      })}
                      placeholder="كلمة السر"
                      type="password"
                    />
                    {errors.password && (
                      <span className="error">password is required</span>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="switcher">
                    مستخدم جديد؟{" "}
                    <Link className="link" to="/register">
                      انشاء حساب
                    </Link>
                  </p>
                  <button
                    style={{ width: "200px" }}
                    type="submit"
                    className="btn-black"
                  >
                    تسجيل الدخول
                  </button>
                </div>
              </form>
            </div>
            {isLoading && (
              <div className="text-center pre-loader">
                <div className="spinner-border" role="status"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
