import React, { useEffect, useState } from "react";
import "./ConsultingAndStudies.scss";
import { useLanguage } from "../../contexts/LanguageContext";

const ConsultingAndStudies = () => {
  const { language } = useLanguage();
  const [studiesData, setStudiesData] = useState({
    studies: "",
    studies_image: "",
  });

  useEffect(() => {
    const fetchStudiesData = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/website_settings/?lang=${language}`
        );
        const data = await response.json();

        if (data.results.length > 0) {
          const result = data.results[0];
          setStudiesData({
            studies: result.studies,
            studies_image: result.studies_image,
          });
        }
      } catch (error) {
        console.error("Error fetching studies data:", error);
      }
    };

    fetchStudiesData();
  }, [language]);

  const content = {
    ar: {
      title: "الاستشارات و الدراسات",
    },
    en: {
      title: "Consulting and Studies",
    },
  };

  const currentContent = content[language];
  return (
    <div
      className={`consulting-and-studies ${
        language === "en" ? "consulting-and-studies-en" : ""
      }`}
    >
      <div className="content">
        <div className="text-content">
          <h3>{currentContent.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: studiesData.studies }} />
        </div>
        <div className="image-content">
          <img src={studiesData.studies_image} alt={currentContent.title} />
        </div>
      </div>
    </div>
  );
};

export default ConsultingAndStudies;
