import React, { useEffect, useState } from "react";
import axios from "axios";
import aboutImg from "../../images/about-us.jpeg";
import FeatureSection from "../FeatureSection/FeatureSection";
import "./About.scss";
import { useLanguage } from "../../contexts/LanguageContext";

const About = () => {
  const [aboutData, setAboutData] = useState({ about: "", about_image: null });
  const { language } = useLanguage();

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get(
          `https://admin.internationalskillsacademy.com/api/website_settings/?lang=${language}`
        );
        if (response.data.results.length > 0) {
          const { about, about_image } = response.data.results[0];
          setAboutData({ about, about_image });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAboutData();
  }, [language]);

  const content = {
    ar: {
      title: "من نحن",
    },
    en: {
      title: "About Us",
    },
  };

  const currentContent = content[language];
  return (
    <>
      <div className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 ps-md-5 mb-5 mb-md-0">
              {aboutData.about_image ? (
                <img
                  className="img-fluid shadow"
                  src={aboutData.about_image}
                  alt="About"
                />
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6">
              <div className="about-info">
                <h3>{currentContent.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: aboutData.about }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeatureSection />
    </>
  );
};

export default About;
