import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import paymentGateway from "../../images/online-payment.png";
import Logo from "../../images/Layer 0.png";
import { useLanguage } from "../../contexts/LanguageContext";

const Footer = () => {
  const { language } = useLanguage();

  const content = {
    ar: {
      academyDescription:
        "- أكاديمية متخصصة بتنمية المهارات و الموارد البشرية من بداية تكوين الشخصية للوصول إلى ممارسة كافة المهارات الحياتية بكفاءة عالية.\n- رؤية مختلفة لتطوير الذات و المجتمع .\n-مرخصة و معتمدة في الجمهورية العربية السورية",
      taxNumber: "رقم التسجيل الضريبي : 110160070530",
      importantLinks: "روابط هامة",
      offersAndDiscounts: "العروض والخصومات",
      certificateVerification: "التحقق من الشهادات",
      customerService: "الشكاوى وخدمة العملاء",
      traineeReviews: "آراء وتقييمات المتدربين في الاكاديمية",
      attendanceRules: "القواعد التنظيمية لحضور الدورات التدريبية",
      termsAndPrivacy: "الشروط والأحكام | سياسة الخصوصية",
      contactUs: "التواصل معنا",
      address:
        "الجمهورية العربية السورية\nطرطوس - المنشية -خلف مبنى المحافظة -",
    },
    en: {
      academyDescription:
        "- An academy specialized in developing skills and human resources from the beginning of personality formation to reach the practice of all life skills with high efficiency.\n- A different vision for self and community development.\n- Licensed and accredited in the Syrian Arab Republic",
      taxNumber: "Tax Registration Number: 110160070530",
      importantLinks: "Important Links",
      offersAndDiscounts: "Offers and Discounts",
      certificateVerification: "Certificate Verification",
      customerService: "Complaints and Customer Service",
      traineeReviews: "Trainee Reviews and Ratings",
      attendanceRules: "Attendance Rules for Training Courses",
      termsAndPrivacy: "Terms and Conditions | Privacy Policy",
      contactUs: "Contact Us",
      address:
        "Syrian Arab Republic\nTartous - Al Manshiyah - Behind the Governorate Building -",
    },
  };

  const currentContent = content[language];

  return (
    <div className={`footer ${language === "en" ? "footer-en" : ""}`}>
      <div
        className="container"
        style={{ maxWidth: "95%", paddingLeft: "0px" }}
      >
        <div className="row" style={{ paddingLeft: "0px" }}>
          <div className="col-sm-6 col-lg-3">
            <h3 className="logo mb-3">ISA</h3>
            <p className="par-aca" style={{ width: "80%" }}>
              {currentContent.academyDescription}
              <br />
              {currentContent.taxNumber}
            </p>
          </div>
          <div className="col-sm-6 col-lg-3">
            <h5>{currentContent.importantLinks}</h5>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="link" to="/offers">
                  {currentContent.offersAndDiscounts}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/verification-section">
                  {currentContent.certificateVerification}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/customer-service">
                  {currentContent.customerService}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/">
                  {currentContent.traineeReviews}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/rules">
                  {currentContent.attendanceRules}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="link" to="/privacy">
                  {currentContent.termsAndPrivacy}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-3">
            <h5>{currentContent.contactUs}</h5>
            <p
              className="p-cen"
              style={{
                direction: language === "ar" ? "ltr" : "rtl",
                textAlign: language === "ar" ? "right" : "left",
              }}
            >
              {currentContent.address}
            </p>
            <p
              className="p-cen"
              style={{
                direction: "ltr",
                textAlign: language === "ar" ? "right" : "left",
              }}
            >
              <a href="tel:+963933954480">+ 963 933 954 480</a>
              <br />
              <a href="tel:+963988954443">+ 963 988 954 443</a>
              <br />
              <a href="tel:+963432326649">+ 963 432 326 649</a>
              <br />
              <a href="tel:+963432326650">+ 963 432 326 650</a>
              <br />
              <a href="tel:+963432326651">+ 963 432 326 651</a>
            </p>
            <a href="mailto:isaacademy023@gmail.com">isaacademy023@gmail.com</a>
          </div>
          <div className="col-sm-6 col-lg-3">
            <img className="img-fluid" src={Logo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
