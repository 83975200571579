import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Contact.scss";
import { useForm } from "react-hook-form";
import { useLanguage } from "../../contexts/LanguageContext";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [contactImage, setContactImage] = useState("");
  const { language } = useLanguage();

  const content = {
    ar: {
      title: "لديك استفسار ؟",
      name: "الاسم :",
      email: "الايميل :",
      message: "الرسالة :",
      send: "ارسل",
      namePlaceholder: "اسمك",
      emailPlaceholder: "ايميلك",
      messagePlaceholder: "رسالتك",
      nameRequired: "اسمك مطلوب",
      emailRequired: "الايميل مطلوب",
      messageRequired: "الرسالة مطلوبة",
      successMessage: "تم الإرسال بنجاح!",
      errorMessage: "حدث خطأ، يرجى المحاولة مرة أخرى.",
      sendError: "حدث خطأ في الإرسال، تأكد من صحة البيانات.",
      imageError: "خطأ في جلب الصورة:",
    },
    en: {
      title: "Have a question?",
      name: "Name:",
      email: "Email:",
      message: "Message:",
      send: "Send",
      namePlaceholder: "Your name",
      emailPlaceholder: "Your email",
      messagePlaceholder: "Your message",
      nameRequired: "Name is required",
      emailRequired: "Email is required",
      messageRequired: "Message is required",
      successMessage: "Sent successfully!",
      errorMessage: "An error occurred, please try again.",
      sendError: "An error occurred while sending, please check your data.",
      imageError: "Error fetching image:",
    },
  };

  const currentContent = content[language];
  useEffect(() => {
    const fetchContactImage = async () => {
      try {
        const response = await axios.get(
          `https://admin.internationalskillsacademy.com/api/website_settings/`
        );
        const data = response.data.results[0];
        setContactImage(data.contact_image || "");
      } catch (error) {
        console.error(currentContent.imageError, error);
      }
    };

    fetchContactImage();
  }, [currentContent.imageError]);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `https://admin.internationalskillsacademy.com/api/contact/`,
        data
      );

      if (response) {
        setSuccessMessage(currentContent.successMessage);
        reset();
      } else {
        setErrorMessage(currentContent.errorMessage);
      }
    } catch (error) {
      console.error(currentContent.sendError, error);
      setErrorMessage(currentContent.sendError);
    }
  };

  return (
    <div className="contact">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-5 mb-lg-0 pe-lg-5">
            {contactImage && (
              <img className="img-fluid" src={contactImage} alt="" />
            )}
          </div>
          <div className="col-lg-6 ps-lg-5">
            <div className="px-lg-3">
              <h3>{currentContent.title}</h3>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-1">
                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor="">{currentContent.name}</label>
                    <input
                      className="form-control"
                      {...register("name", { required: true })}
                      placeholder={currentContent.namePlaceholder}
                    />
                    {errors.name && (
                      <span className="error">
                        {currentContent.nameRequired}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="">{currentContent.email}</label>
                    <input
                      className="form-control"
                      {...register("email", { required: true })}
                      placeholder={currentContent.emailPlaceholder}
                    />
                    {errors.email && (
                      <span className="error">
                        {currentContent.emailRequired}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="">{currentContent.message}</label>
                    <textarea
                      className="form-control"
                      {...register("message", { required: true })}
                      placeholder={currentContent.messagePlaceholder}
                    />
                    {errors.message && (
                      <span className="error">
                        {currentContent.messageRequired}
                      </span>
                    )}
                  </div>
                </div>
                <button type="submit" className="btn-black">
                  {currentContent.send}
                </button>
              </form>
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
