import "./Offers.scss";
import { useLanguage } from "../../contexts/LanguageContext";

export default function Offers() {
  const { language } = useLanguage();

  const content = {
    ar: {
      title: "دليل الخصومات على الكورسات والدورات التدريبية",
      description:
        "دليل لأحدث العروض والخصومات المقدمة على الكورسات والبرامج التدريبية المقدمة من أكاديمية المهارات الدولية للتدريب، عروض حصرية وخصومات غير مسبوقة ممتدة لفترة محدودة. اغتنم الفرصة في الحال!",
      exclusiveOffer: "عرض حصري للشركات والمجموعات:",
      exclusiveOfferDetails:
        "أكاديمية المهارات الدولية تقدم عرضًا خاصًا للشركات والمجموعات عند التسجيل والاشتراك في أي برنامج تدريبي.",
      discountDetails: [
        { trainees: "عدد المتدربين 2", discount: "10%" },
        { trainees: "عدد المتدربين 3", discount: "15%" },
        { trainees: "عدد المتدربين 4", discount: "20%" },
        { trainees: "عدد المتدربين 5+", discount: "25%" },
      ],
      specialOffers: "عروض خاصة للمؤسسات الخيرية والجمعيات غير الهادفة للربح:",
      specialOffersDetails:
        "عرض خاص ودائم لمؤسسات العمل الخيري في إطار حرص أكاديمية المهارات الدولية للتدريب على القيام بواجبات المسؤولية الاجتماعية ودورها تجاه المجتمع. تتشرف الأكاديمية بتوفير خصم مباشر 15% على رسوم التسجيل والاشتراك في جميع الدورات والبرامج التدريبية، سواء للأفراد العاملين في تلك المؤسسات أو المجموعات، وسواء كان التدريب في مقر الأكاديمية أو في مقر المؤسسة.",
    },
    en: {
      title: "Discount Guide for Courses and Training Programs",
      description:
        "A guide to the latest offers and discounts on courses and training programs offered by the International Skills Academy for Training. Exclusive offers and unprecedented discounts available for a limited time. Seize the opportunity now!",
      exclusiveOffer: "Exclusive Offer for Companies and Groups:",
      exclusiveOfferDetails:
        "The International Skills Academy offers a special offer for companies and groups when registering and subscribing to any training program.",
      discountDetails: [
        { trainees: "2 trainees", discount: "10%" },
        { trainees: "3 trainees", discount: "15%" },
        { trainees: "4 trainees", discount: "20%" },
        { trainees: "5+ trainees", discount: "25%" },
      ],
      specialOffers:
        "Special Offers for Charitable Organizations and Non-Profits:",
      specialOffersDetails:
        "A special and permanent offer for charitable organizations as part of the International Skills Academy's commitment to social responsibility and its role towards the community. The academy is honored to provide a direct 15% discount on registration and subscription fees for all courses and training programs, whether for individuals working in these organizations or groups, and whether the training is at the academy's premises or at the organization's premises.",
    },
  };

  const currentContent = content[language];

  return (
    <div className="offers" style={{ paddingTop: "220px", padding: "50px" }}>
      <div className="overlay"></div>
      <h2>{currentContent.title}</h2>
      <p style={{ lineHeight: "2.5" }}>{currentContent.description}</p>
      <p>
        <strong style={{ fontSize: "17px" }}>
          {currentContent.exclusiveOffer}
        </strong>
        <br />
        {currentContent.exclusiveOfferDetails}
        <br />
        {currentContent.discountDetails.map((detail, index) => (
          <div key={index}>
            <strong>{detail.trainees}:</strong> {detail.discount}
          </div>
        ))}
      </p>
      <p>
        <strong style={{ fontSize: "17px" }}>
          {currentContent.specialOffers}
        </strong>
        <br />
        {currentContent.specialOffersDetails}
      </p>
    </div>
  );
}
