import React, { useState } from "react";
import "./VerificationSection.scss";
import { useLanguage } from "../../contexts/LanguageContext";

const VerificationSection = () => {
  const [certificateId, setCertificateId] = useState("");
  const [certificateData, setCertificateData] = useState(null);
  const [message, setMessage] = useState("");
  const { language } = useLanguage();

  const content = {
    ar: {
      title: "تحقق من الشهادة",
      placeholder: "أدخل معرف الشهادة",
      button: "تحقق",
      errorMessage: "خطأ في الاتصال بالـ API",
      notFoundMessage: "لم يتم العثور على الشهادة",
      enterIdMessage: "يرجى إدخال معرف الشهادة",
      verifiedMessage: "تم التحقق من الشهادة: ",
      underText: `نظام التحقق من الشهادات المعتمدة من اكاديمية ISA للتدريب، هو نظام تم
        تطويره خصيصا للتحقق من صحة اعتماد الشهادة الخاصة بأكاديمية ISA والصادرة
        من الأكاديمية اعتبارا من تاريخ 1 إبريل 2024 ، وتعد اي شهادة معتمدة من
        الأكاديمية منذ هذا التاريخ قابلة للتحقق منها ومن صحة اعتمادها من ادارة
        التدريب التابعة لأكاديمية ISA للتدريب من خلال هذه الصفحة، كل المطلوب هو
        البحث في الشهادة عن الرقم الخاص بها (Ref. No)، ثم ضع هذا الرقم في صندوق
        البحث أدناه، واذا كانت الشهادة صحيحة فسيقوم النظام بعرض الشهادة
        وبياناتها، واذا كانت الشهادة غير معتمدة من الأكاديمية فسيظهر لك ذلك.`,
    },
    en: {
      title: "Certificate Verification",
      placeholder: "Enter Certificate ID",
      button: "Verify",
      errorMessage: "Error connecting to the API",
      notFoundMessage: "Certificate not found",
      enterIdMessage: "Please enter the certificate ID",
      verifiedMessage: "Certificate verified: ",
      underText: `The ISA Training Academy's accredited certificate verification system is a system
        specifically developed to verify the validity of the ISA Academy certificate issued
        by the academy as of April 1, 2024. Any certificate accredited by the academy since
        this date is verifiable for its validity by the ISA Training Academy's training
        management through this page. All you need to do is find the specific number on the
        certificate (Ref. No), then enter this number in the search box below. If the
        certificate is valid, the system will display the certificate and its details. If
        the certificate is not accredited by the academy, it will show you that.`,
    },
  };

  const currentContent = content[language];

  const handleInputChange = (e) => {
    setCertificateId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (certificateId) {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/certeficate/?id=${certificateId}`
        );
        if (!response.ok) {
          throw new Error(currentContent.errorMessage);
        }
        const data = await response.json();

        const certificate = data.results.find(
          (cert) => cert.serial_number === certificateId
        );

        if (certificate) {
          setCertificateData(certificate);
          setMessage("");
        } else {
          setMessage(currentContent.notFoundMessage);
          setCertificateData(null);
        }
      } catch (error) {
        setMessage(error.message);
        setCertificateData(null);
      }
    } else {
      setMessage(currentContent.enterIdMessage);
    }
  };

  return (
    <div className="certificate-verification">
      <div className="overlay"></div>
      <h2>{currentContent.title}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder={currentContent.placeholder}
          value={certificateId}
          onChange={handleInputChange}
          required
        />
        <button type="submit">{currentContent.button}</button>
      </form>
      {message && <p className="message">{message}</p>}
      {certificateData && (
        <div>
          <p style={{ color: "green" }}>
            {currentContent.verifiedMessage} {certificateData.program.title}
          </p>
          <a
            href={certificateData.file}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="show-certificate">عرض الشهادة</button>
          </a>
        </div>
      )}
      <p
        className="under-text"
        style={{
          marginTop: "20px",
          width: "60%",
          color: "white",
          textAlign: "center",
          lineHeight: 2.5,
        }}
      >
        {currentContent.underText}
      </p>
    </div>
  );
};

export default VerificationSection;
