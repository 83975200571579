import React, { useEffect, useState } from "react";
import "./Library.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faClock } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useAuth from "../../hooks/useAuth";
import { useLanguage } from "../../contexts/LanguageContext";

const Library = () => {
  const [pdfs, setPdfs] = useState([]);
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [selectedPdf, setSelectedPdf] = useState(null);
  const { user } = useAuth();
  const [userCouponStatus, setUserCouponStatus] = useState({});
  const { language } = useLanguage();

  const content = {
    ar: {
      couponPlaceholder: "أدخل كود الكوبون",
      couponSuccess: "تم تطبيق الكوبون بنجاح!",
      couponError: "يرجى إدخال كود كوبون صحيح.",
      couponRequired: "يرجى إدخال الكوبون.",
      detailsUnavailable: "غير متوفر",
      pdfLabel: "PDF",
      dateLabel: "تاريخ الإنشاء",
      title: "أدخل كود الكوبون",
      cancel: "إلغاء",
      confirm: "تأكيد",
    },
    en: {
      couponPlaceholder: "Enter coupon code",
      couponSuccess: "Coupon applied successfully!",
      couponError: "Please enter a valid coupon code.",
      couponRequired: "Please enter the coupon.",
      detailsUnavailable: "Not available",
      pdfLabel: "PDF",
      dateLabel: "Creation Date",
      title: "Enter Coupon Code",
      cancel: "Cancel",
      confirm: "Confirm",
    },
  };

  const currentContent = content[language];

  useEffect(() => {
    const fetchPdfs = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/library/?lang=${language}`
        );
        const data = await response.json();
        setPdfs(data.results);
      } catch (error) {
        console.error("Error fetching PDF data:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/library-categories/?lang=${language}`
        );
        const data = await response.json();
        setCategories(data.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchPdfs();
    fetchCategories();
  }, [language]);

  const handleDetails = (pdf) => {
    const isFree = pdf.categories.some((category) => category.slug === "free");
    const couponApplied = userCouponStatus[pdf.id];

    if (isFree || couponApplied) {
      window.open(pdf.file, "_blank");
    } else {
      if (!user) {
        history.push("/login");
        return;
      }
      setSelectedPdf(pdf);
      setShowCouponModal(true);
    }
  };

  const handleCouponSubmit = async () => {
    if (!user) {
      history.push("/login");
      return;
    }

    if (!couponCode) {
      alert(currentContent.couponRequired);
      return;
    }

    const couponCodeRegex = /^[a-zA-Z0-9-]{36}$/;
    if (!couponCodeRegex.test(couponCode)) {
      alert(currentContent.couponError);
      return;
    }

    const couponData = { coupon_code: couponCode };

    try {
      const response = await fetch(
        `https://admin.internationalskillsacademy.com/api/library/${selectedPdf.id}/enroll/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${user.token}`,
          },
          body: JSON.stringify(couponData),
        }
      );

      if (!response.ok) {
        throw new Error("فشل في تطبيق الكوبون");
      }

      setUserCouponStatus((prev) => ({ ...prev, [selectedPdf.id]: true }));
      setShowCouponModal(false);
      window.open(selectedPdf.file, "_blank");
      alert(currentContent.couponSuccess);
    } catch (error) {
      console.error("هناك مشكلة أثناء تطبيق الكوبون:", error);
      alert(error.message);
    }
  };

  return (
    <>
      <div className="row">
        {pdfs.map((pdf) => (
          <div key={pdf.id} className="col-sm-12 col-md-6 col-lg-3">
            <div onClick={() => handleDetails(pdf)} className="pdf-box shadow">
              <img className="img-fluid" src={pdf.image} alt={pdf.title} />
              <div className="info">
                <p className="category">
                  {pdf.categories[0]?.title ||
                    currentContent.detailsUnavailable}
                </p>
                <h6 className="title">{pdf.title}</h6>
                <div className="d-flex justify-content-between">
                  <span>
                    <FontAwesomeIcon className="icon" icon={faFilePdf} />{" "}
                    {currentContent.pdfLabel}
                  </span>
                  <span>
                    <FontAwesomeIcon className="icon" icon={faClock} />{" "}
                    {currentContent.dateLabel}:{" "}
                    {new Date(pdf.date_created).toLocaleDateString()}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal show={showCouponModal} onHide={() => setShowCouponModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder={currentContent.couponPlaceholder}
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowCouponModal(false)}>
            {currentContent.cancel}
          </Button>
          <Button
            style={{ backgroundColor: "#2f2d52", border: "1px solid #2f2d52" }}
            onClick={handleCouponSubmit}
          >
            {currentContent.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Library;
