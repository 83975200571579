import "./Regulations.scss";
import { useLanguage } from "../../contexts/LanguageContext";

export default function Regulations() {
  const { language } = useLanguage();

  const content = {
    ar: {
      title: "لائحة الشروط والاحكام الخاصة بالتسجيل في برنامج تدريبي",
      introduction: `عزيزي المتدرب، أهلا بانضمامكم الى قائمة متدربي ISA أكاديمي، الذين نفخر بخدمتهم طوال مدة دراستهم معنا. لتقديم أفضل خدمة تدريبية لكم، أرجو من سيادتكم الاطلاع على قائمة الشروط والأحكام الخاصة بالأكاديمية، والموافقة عليها قبل اتمام خطوات التسجيل، وذلك في إطار تنظيم الحقوق والواجبات الخاصة بالتسجيل في برامجنا التدريبية.`,
      termsTitle: "الشروط والأحكام:",
      terms: [
        "لكل دورة حد ادنى من عدد المشاركين لتستطيع الأكاديمية بدء البرنامج التدريبي فوراً، وفور الوصول للحد الأدنى يتم تحديد تاريخ بدء الدورة، ويمكن تسجيل حجوزات جديدة حتى اكتمال العدد أو تاريخ البدء المحدد أيهما أقرب.",
        "التسجيل في أي من برامجنا التدريبية يعني التزام الأكاديمية بتقديم الخدمات التدريبية الواجبة لكم، وتوفير المادة التدريبية سواء مطبوعة أو الكترونية – حسب طبيعة البرنامج التدريبي – وتنظيم المحاضرات في المواعيد المعلنة، والتي يتم اعلامكم بها سواء وقت التسجيل أو بعده.",
        "أي تعديل في مواعيد البرامج التدريبية يتم بالتنسيق مع المتدربين، وتتحرى فيه الأكاديمية - قدر المستطاع - المواعيد الملائمة للمتدربين.",
        "إذا لم يتم تحديد تاريخ لبدء الدورة خلال مدة أقصاها 30 يوم من تاريخ السداد، يحق للمتدرب أن يسترد مدفوعاته كاملة أو الانتظار حتى اكتمال العدد، أو تحويل تلك الرسوم إلى دورة تدريبية أخرى، وتتم عملية الاسترداد بنفس طريقة الدفع.",
        "إذا تم تحديد تاريخ بداية البرنامج التدريبي، فإنه لا يحق للمتدرب حينها وتحت أي ظرف الانسحاب من البرنامج التدريبي أو استرداد أي رسوم مدفوعة.",
        "في الدورات التي تتم فيها الدراسة أون لاين عبر الانترنت، يكون تسجيل الحضور من خلال التواجد الفعلي في وقت المحاضرة، كذلك يجب على المتدرب التفاعل مع المحاضر كشرط أساسي لإثبات الحضور، ولا يُمثل مشاهدة التسجيل الخاص بالمحاضرة كحضور في هذه المحاضرة أبداً، وبالتالي لا يحق للمتدرب احتساب مشاهدته لأي محاضرة مسجلة كحضور فيها.",
        "لضمان الحضور والحصول على الشهادة التدريبية، يجب على كل متدرب استكمال ملفه بالأكاديمي بصورة واضحة من وثيقة إثبات الشخصية، حيث لن يتمكن المتدرب من حضور المحاضرات إلا بعد استيفاء هذا الشرط.",
        "يتم استخراج الشهادة التدريبية في نهاية البرنامج التدريبي، بنفس الاسم المثبت في وثيقة إثبات الشخصية.",
        "لا يحق للمتدرب الحصول على شهادة الحضور في الدورة التدريبية في إحدى الحالات الآتية: عدم استيفاء نسبة حضور 75% من إجمالي ساعات البرنامج التدريبي، عدم سداد كامل مصروفات البرنامج التدريبي، عدم حضور الورش العملية – إن كان البرنامج التدريبي يشترط ذلك، حصول المتدرب على تقييم سلبي من المُحاضر على مدار الكورس، يعكس عدم استيعاب المتدرب للمادة التدريبية بشكل يؤهله للحصول على الشهادة، عدم استيفاء البيانات أو عدم تقديم صورة واضحة من وثيقة إثبات الشخصية للمتدرب.",
        "المصروفات المذكورة بالموقع لا تشمل مصاريف شحن الشهادات.",
        "الشهادات والاعتمادات الاجنبية التي نقدمها مع برامجنا التدريبية صادرة من مراكز تدريب أجنبية ولا تصدر عن جامعات أو كليات أجنبية.",
      ],
    },
    en: {
      title: "Terms and Conditions for Registration in the Training Program",
      introduction: `Dear Trainee, welcome to the list of ISA Academy trainees, whom we are proud to serve throughout their study period with us. To provide you with the best training service, please review the list of the Academy's terms and conditions, and agree to them before completing the registration steps, as part of organizing the rights and obligations related to registering in our training programs.`,
      termsTitle: "Terms and Conditions:",
      terms: [
        "Each course has a minimum number of participants for the Academy to start the training program immediately. Once the minimum is reached, the start date of the course will be determined, and new bookings can be made until the number is complete or the specified start date, whichever comes first.",
        "Registering in any of our training programs means the Academy's commitment to providing you with the necessary training services and providing the training material, whether printed or electronic – according to the nature of the training program – and organizing lectures at the announced times, which you will be informed of either at the time of registration or afterward.",
        "Any changes to the training program schedules are made in coordination with the trainees, and the Academy will strive - as much as possible - for suitable times for the trainees.",
        "If a start date for the course is not determined within a maximum of 30 days from the payment date, the trainee has the right to fully refund their payments or wait until the number is complete, or transfer those fees to another training course, and the refund will be done in the same payment method.",
        "If the start date of the training program is determined, the trainee then has no right under any circumstances to withdraw from the training program or to refund any paid fees.",
        "In courses conducted online via the internet, attendance is recorded by being physically present at the time of the lecture. The trainee must also interact with the lecturer as a basic condition for proving attendance, and watching the recorded lecture does not count as attendance in that lecture at all; thus, the trainee does not have the right to count watching any recorded lecture as attendance.",
        "To ensure attendance and obtain the training certificate, each trainee must complete their academic file with a clear copy of their identity verification document, as the trainee will not be able to attend the lectures until this condition is met.",
        "The training certificate is issued at the end of the training program, in the same name as stated in the identity verification document.",
        "The trainee has no right to obtain a certificate of attendance in the training course in any of the following cases: failure to meet the attendance rate of 75% of the total hours of the training program, failure to pay the full expenses of the training program, failure to attend practical workshops – if the training program requires that, receiving a negative evaluation from the instructor throughout the course, reflecting the trainee's lack of understanding of the training material in a way that qualifies them to obtain the certificate, failure to meet the data or failure to provide a clear copy of the trainee's identity verification document.",
        "The expenses mentioned on the site do not include the shipping costs of the certificates.",
        "The foreign certificates and accreditations we provide with our training programs are issued by foreign training centers and are not issued by foreign universities or colleges.",
      ],
    },
  };

  const currentContent = content[language];

  return (
    <div className="regulations" style={{ marginTop: "100px" }}>
      <h2>{currentContent.title}</h2>
      <p style={{ lineHeight: "2" }}>{currentContent.introduction}</p>
      <h4 style={{ marginTop: "50px" }}>{currentContent.termsTitle}</h4>
      <ul>
        {currentContent.terms.map((term, index) => (
          <li key={index}>{term}</li>
        ))}
      </ul>
    </div>
  );
}
