// import React from "react";
// import { useForm } from "react-hook-form";
// import { useHistory, useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
// import googleIcon from "../../images/google1.png";
// import "./LoginRegister.scss";

// const Register = () => {
//   const { user, handleCreateUser, error, isLoading } = useAuth();
//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { errors },
//   } = useForm();

//   const location = useLocation();
//   const history = useHistory();

//   const onSubmit = (data) => {
//     handleCreateUser(data, location, history);
//     console.log(data, location, history);
//     reset();
//   };

//   return (
//     <div className="login-section">
//       <div className="container">
//         <div className="row">
//           <div className="col">
//             {error && <p className="failure-alert">{error}</p>}
//             {user?.username && (
//               <p className="success-alert">Account created successfully</p>
//             )}
//             <div className="form-box">
//               <h4>انشاء حساب جديد!</h4>
//               <div className="divider">
//                 <span> انشاء حساب عبر الايميل</span>
//               </div>
//               <form onSubmit={handleSubmit(onSubmit)} className="mb-0">
//                 <div className="row">
//                   <div className="form-group col-12">
//                     <label htmlFor="">اسم المستخدم</label>
//                     <input
//                       className="form-control"
//                       type="text"
//                       defaultValue=""
//                       {...register("username", { required: true })}
//                       placeholder="اسم المستخدم"
//                     />
//                     {errors.username && (
//                       <span className="error">Username is required</span>
//                     )}
//                   </div>

//                   <div className="form-group col-12">
//                     <label htmlFor="">الاسم الأول</label>
//                     <input
//                       className="form-control"
//                       type="text"
//                       defaultValue=""
//                       {...register("firstName", { required: true })}
//                       placeholder="الاسم الأول"
//                     />
//                     {errors.firstName && (
//                       <span className="error">First name is required</span>
//                     )}
//                   </div>

//                   <div className="form-group col-12">
//                     <label htmlFor="">الاسم الأخير</label>
//                     <input
//                       className="form-control"
//                       type="text"
//                       defaultValue=""
//                       {...register("lastName", { required: true })}
//                       placeholder="الاسم الأخير"
//                     />
//                     {errors.lastName && (
//                       <span className="error">Last name is required</span>
//                     )}
//                   </div>

//                   <div className="form-group col-12">
//                     <label htmlFor="">الايميل</label>
//                     <input
//                       className="form-control"
//                       type="email"
//                       defaultValue=""
//                       {...register("email", { required: true })}
//                       placeholder="الايميل"
//                     />
//                     {errors.email && (
//                       <span className="error">Email is required</span>
//                     )}
//                   </div>

//                   <div className="form-group col-12">
//                     <label htmlFor="">كلمة السر</label>
//                     <input
//                       className="form-control"
//                       type="password"
//                       defaultValue=""
//                       {...register("password", { required: true })}
//                       placeholder="كلمة السر"
//                     />
//                     {errors.password && (
//                       <span className="error">Password is required</span>
//                     )}
//                   </div>
//                   <div className="form-group col-12">
//                     <label htmlFor="">اعادة كلمة السر </label>
//                     <input
//                       className="form-control"
//                       type="password"
//                       defaultValue=""
//                       {...register("password", { required: true })}
//                       placeholder="اعادة كلمة السر"
//                     />
//                     {errors.password && (
//                       <span className="error">Password is required</span>
//                     )}
//                   </div>
//                 </div>
//                 <div className="d-flex justify-content-between">
//                   <p className="switcher">
//                     أنت بالفعل مستخدم ؟{" "}
//                     <Link className="link" to="/login">
//                       تسحيل الدخول
//                     </Link>
//                   </p>
//                   <button
//                     style={{ width: "200px" }}
//                     type="submit"
//                     className="btn-black"
//                   >
//                     انشاء حساب
//                   </button>
//                 </div>
//               </form>
//             </div>
//             {isLoading && (
//               <div className="text-center pre-loader">
//                 <div className="spinner-border" role="status"></div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Register;

import React from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import googleIcon from "../../images/google1.png";
import "./LoginRegister.scss";

const Register = () => {
  const { user, handleCreateUser, error, isLoading } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const history = useHistory();

  const onSubmit = (data) => {
    handleCreateUser(data, location, history);
    console.log(data, location, history);
    reset();
  };

  return (
    <div className="login-section">
      <div className="container">
        <div className="row">
          <div className="col">
            {error && <p className="failure-alert">{error}</p>}
            {user?.username && (
              <p className="success-alert">Account created successfully</p>
            )}
            <div className="form-box">
              <h4>انشاء حساب جديد!</h4>
              <div className="divider">
                <span> انشاء حساب عبر الايميل</span>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-0">
                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor="">اسم المستخدم</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue=""
                      {...register("username", {
                        required: "اسم المستخدم مطلوب",
                        pattern: {
                          value: /^[A-Za-z]+$/,
                          message: "اسم المستخدم يجب أن يتكون من حروف فقط",
                        },
                      })}
                      placeholder="اسم المستخدم"
                    />
                    {errors.username && (
                      <span className="error">{errors.username.message}</span>
                    )}
                  </div>

                  <div className="form-group col-12">
                    <label htmlFor="">الاسم الأول</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue=""
                      {...register("firstName", {
                        required: "الاسم الأول مطلوب",
                      })}
                      placeholder="الاسم الأول"
                    />
                    {errors.firstName && (
                      <span className="error">{errors.firstName.message}</span>
                    )}
                  </div>

                  <div className="form-group col-12">
                    <label htmlFor="">الاسم الأخير</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue=""
                      {...register("lastName", {
                        required: "الاسم الأخير مطلوب",
                      })}
                      placeholder="الاسم الأخير"
                    />
                    {errors.lastName && (
                      <span className="error">{errors.lastName.message}</span>
                    )}
                  </div>

                  <div className="form-group col-12">
                    <label htmlFor="">الايميل</label>
                    <input
                      className="form-control"
                      type="email"
                      defaultValue=""
                      {...register("email", { required: "الايميل مطلوب" })}
                      placeholder="الايميل"
                    />
                    {errors.email && (
                      <span className="error">{errors.email.message}</span>
                    )}
                  </div>

                  <div className="form-group col-12">
                    <label htmlFor="">كلمة السر</label>
                    <input
                      className="form-control"
                      type="password"
                      defaultValue=""
                      {...register("password", {
                        required: "كلمة السر مطلوبة",
                        minLength: {
                          value: 8,
                          message: "يجب أن تكون كلمة السر على الأقل 8 أحرف",
                        },
                        pattern: {
                          value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message:
                            "كلمة السر يجب أن تحتوي على أرقام وحروف وعلامات مميزة",
                        },
                      })}
                      placeholder="كلمة السر"
                    />
                    {errors.password && (
                      <span className="error">{errors.password.message}</span>
                    )}
                  </div>

                  <div className="form-group col-12">
                    <label htmlFor="">اعادة كلمة السر</label>
                    <input
                      className="form-control"
                      type="password"
                      defaultValue=""
                      {...register("confirmPassword", {
                        required: "يرجى تأكيد كلمة المرور",
                        validate: (value) =>
                          value === watch("password") ||
                          "كلمة المرور غير متطابقة",
                      })}
                      placeholder="اعادة كلمة السر"
                    />
                    {errors.confirmPassword && (
                      <span className="error">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="switcher">
                    أنت بالفعل مستخدم ؟{" "}
                    <Link className="link" to="/login">
                      تسحيل الدخول
                    </Link>
                  </p>
                  <button
                    style={{ width: "200px" }}
                    type="submit"
                    className="btn-black"
                  >
                    انشاء حساب
                  </button>
                </div>
              </form>
            </div>
            {isLoading && (
              <div className="text-center pre-loader">
                <div className="spinner-border" role="status"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
