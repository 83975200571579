import React, { useEffect, useState } from "react";
import Rating from "react-rating";
import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faClock,
  faBookReader,
  faLanguage,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import "./CourseDetails.scss";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useLanguage } from "../../contexts/LanguageContext";

const CourseDetails = () => {
  const { language } = useLanguage();
  const [details, setDetails] = useState({});
  const [activeTab, setActiveTab] = useState("overview");
  const { handleSubmit, reset } = useForm();
  const { courseId } = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const [lessons, setLessons] = useState([]);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetch(
      `https://admin.internationalskillsacademy.com/api/courses/${courseId}/?user=${user.id}&language=${language}`
    )
      .then((res) => {
        if (!res.ok) throw new Error("Network response was not ok");
        return res.json();
      })
      .then((data) => {
        if (data) {
          setDetails(data);
          loadLessons(data.lessonss);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [courseId, user.id, language]);

  const loadLessons = async (lessonIds) => {
    const articles = await fetch(
      `https://admin.internationalskillsacademy.com/api/uploads/?language=${language}`
    );
    const videos = await fetch(
      `https://admin.internationalskillsacademy.com/api/upload-videos/?language=${language}`
    );

    const articlesData = await articles.json();
    const videosData = await videos.json();

    const combinedLessons = [
      ...articlesData.results.map((article) => ({
        type: "article",
        ...article,
      })),
      ...videosData.results.map((video) => ({ type: "video", ...video })),
    ];

    setLessons(combinedLessons);
  };

  const {
    title = "",
    categories = [],
    lessonss = [],
    price = 0,
    image = "",
    summary = "",
    author = {},
    is_enrolled,
    duration = 0,
    lessons_number = 0,
  } = details;

  useEffect(() => {
    const enrolledCourses =
      JSON.parse(localStorage.getItem("enrolledCourses")) || {};
    if (enrolledCourses[courseId]) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        is_enrolled: true,
      }));
    }
  }, [courseId]);

  const onSubmit = (data) => {
    if (!user) {
      alert(
        language === "ar" ? "يرجى تسجيل الدخول أولاً." : "Please log in first."
      );
      return;
    }

    const couponFormat = /^[a-zA-Z0-9-]{36}$/;

    if (!couponFormat.test(couponCode)) {
      alert(language === "ar" ? "الكوبون غير صالح" : "Invalid coupon");
      return;
    }

    const couponData = { coupon_code: couponCode };

    fetch(
      `https://admin.internationalskillsacademy.com/api/courses/${courseId}/enroll/?language=${language}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user.token}`,
        },
        body: JSON.stringify(couponData),
      }
    )
      .then((res) => {
        if (res.ok) {
          setShowSuccessMessage(true);
          reset();
          setShowCouponModal(false);
          setCouponCode("");
          setDetails((prevDetails) => ({
            ...prevDetails,
            is_enrolled: true,
          }));
          return res.json();
        }
        return res.json().then((data) => {
          throw new Error(
            data.message ||
              (language === "ar"
                ? "فشل في تطبيق الكوبون"
                : "Coupon application failed")
          );
        });
      })
      .catch((error) => {
        console.error("هناك مشكلة في عملية السحب:", error);
        setErrorMessage(error.message);
      });
  };

  const handleCouponSubmit = () => {
    setShowCouponModal(true);
  };

  const handleVideoProgress = (lessonId) => (event) => {
    const { currentTime, duration } = event.target;
    const percentage = (currentTime / duration) * 100;

    if (percentage >= 70) {
      sendWatchHistory(user.id, courseId, lessonId);
    }
  };

  const sendWatchHistory = (userId, courseId, lessonId) => {
    fetch(
      `https://admin.internationalskillsacademy.com/api/watch_history/?language=${language}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${user.token}`,
        },
        body: JSON.stringify({
          user: userId,
          course: courseId,
          lesson: lessonId,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(
              data.message ||
                (language === "ar"
                  ? "فشل في تسجيل بيانات المشاهدة"
                  : "Failed to record watch data")
            );
          });
        }
        console.log(
          language === "ar"
            ? "تم تسجيل بيانات المشاهدة بنجاح"
            : "Watch data recorded successfully"
        );
      })
      .catch((error) => {
        console.error("هناك مشكلة في تسجيل بيانات المشاهدة:", error);
        setErrorMessage(error.message);
      });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "overview":
        return (
          <div className="indicator-details">
            <h4>
              {language === "ar" ? "تفاصيل الدورة التعليمية" : "Course Details"}
            </h4>
            <p>{summary}</p>
          </div>
        );
      case "curriculum":
        return (
          <div>
            <h4>{language === "ar" ? "المقرر" : "Curriculum"}</h4>
            {!user ? (
              <div>
                <p>
                  {language === "ar"
                    ? "يجب تسجيل الدخول لعرض محتوى الدروس."
                    : "You need to log in to view lesson content."}
                </p>
                <p style={{ marginBottom: "70px" }}>
                  {language === "ar"
                    ? "قم بتسجيل الدخول لرؤية تفاصيل الدروس. (يمكنك أن ترى فقط العنواين)"
                    : "Log in to see lesson details. (You can only see the titles)"}
                </p>
                <ul>
                  {lessonss.map((lesson) => (
                    <li key={lesson.id}>
                      {lesson.title ||
                        (language === "ar"
                          ? "درس غير متوفر"
                          : "Lesson not available")}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <>
                {is_enrolled == true ? (
                  <Accordion>
                    {lessonss.map((lesson, index) => (
                      <Accordion.Item key={lesson.id} eventKey={String(index)}>
                        <Accordion.Header>
                          {lesson.title ||
                            (language === "ar"
                              ? "درس غير متوفر"
                              : "Lesson not available")}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            {lesson.short_description ||
                              (language === "ar"
                                ? "وصف غير متوفر"
                                : "Description not available")}
                          </p>
                          <p>
                            {language === "ar" ? "المحتوى:" : "Content:"} <br />
                            {lesson.long_description ||
                              (language === "ar"
                                ? "لا يوجد محتوى متوفر"
                                : "No content available")}
                          </p>
                          {lesson.videos && (
                            <div>
                              <h5>{language === "ar" ? "فيديو:" : "Video:"}</h5>
                              <video
                                width="400"
                                controls
                                onTimeUpdate={handleVideoProgress(lesson.id)}
                              >
                                <source
                                  src={lesson.videos.video}
                                  type="video/mp4"
                                />
                                {language === "ar"
                                  ? "متصفحك لا يدعم تشغيل الفيديو."
                                  : "Your browser does not support the video tag."}
                              </video>
                            </div>
                          )}
                          <br />
                          {lesson.files && lesson.files.length > 0 && (
                            <div>
                              <h5>
                                {language === "ar" ? "الملفات:" : "Files:"}
                              </h5>
                              <ul>
                                {lesson.files.map((file) => (
                                  <li key={file.id}>
                                    <a
                                      href={file.file}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {file.title ||
                                        (language === "ar" ? "ملف" : "File")}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                ) : (
                  <div>
                    <p>
                      {language === "ar"
                        ? "يجب التسجيل في الدورة لرؤية محتوى الدروس."
                        : "You must enroll in the course to see lesson content."}
                    </p>
                    <ul>
                      {lessonss.map((lesson) => (
                        <li key={lesson.id}>
                          {lesson.title ||
                            (language === "ar"
                              ? "درس غير متوفر"
                              : "Lesson not available")}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
        );
      case "instructor":
        return (
          <div>
            <h4>{language === "ar" ? "المدرب" : "Instructor"}</h4>
            <p>
              {author.username ||
                (language === "ar"
                  ? "اسم المدرب غير متوفر"
                  : "Instructor name not available")}
            </p>
            <p>
              {author.teacherprofile?.bio ||
                (language === "ar"
                  ? "نبذة عن المدرب غير متوفرة"
                  : "Instructor bio not available")}
            </p>
          </div>
        );
      case "reviews":
        return (
          <div>
            <h4>{language === "ar" ? "المراجعات" : "Reviews"}</h4>
            <p>
              {language === "ar"
                ? "ليس لدينا مراجعات بعد."
                : "We have no reviews yet."}
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="details-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-5 mb-lg-0">
            <div className="detail-box">
              <h3 className="title">{title}</h3>
              <p className="category">{categories[0]?.title || ""}</p>
              <div className="instructor">
                <div className="box">
                  <img
                    src={author.teacherprofile?.image || ""}
                    alt={language === "ar" ? "مدرب" : "Instructor"}
                  />
                </div>
                <div className="box">
                  <h5>
                    {language === "ar" ? "أُنشأ من قبل :" : "Created by :"}
                  </h5>
                  <p className="mb-0">{author.username || ""}</p>
                </div>
              </div>
              <div className="bottom-area">
                <ul className="indicator">
                  <li
                    className={activeTab === "overview" ? "active" : ""}
                    onClick={() => setActiveTab("overview")}
                  >
                    {language === "ar"
                      ? "لمحة عن الدورة التعليمية"
                      : "Course Overview"}
                  </li>
                  <li
                    className={activeTab === "curriculum" ? "active" : ""}
                    onClick={() => setActiveTab("curriculum")}
                  >
                    {language === "ar" ? "المقرر" : "Curriculum"}
                  </li>
                  <li
                    className={activeTab === "instructor" ? "active" : ""}
                    onClick={() => setActiveTab("instructor")}
                  >
                    {language === "ar" ? "المدرب" : "Instructor"}
                  </li>
                </ul>
                {renderTabContent()}
              </div>
            </div>
          </div>

          <div className="col-lg-4 ps-lg-5">
            <div className="purchase-course-box">
              <div className="img-box">
                <img className="img-fluid" src={image} alt="" />
              </div>
              <div className="info-box">
                <h4 className="price">{price} SP</h4>
                {user ? (
                  is_enrolled == true ? (
                    <p>
                      {language === "ar"
                        ? "أنت بالفعل مشترك في هذه الدورة التعليمية!"
                        : "You are already enrolled in this course!"}
                    </p>
                  ) : (
                    <button onClick={handleCouponSubmit} className="btn-black">
                      {language === "ar"
                        ? "اشتري هذه الدورة التعليمية"
                        : "Buy this course"}
                    </button>
                  )
                ) : (
                  <button
                    onClick={() => history.push("/login")}
                    className="btn-black"
                  >
                    {language === "ar"
                      ? "اشتري هذه الدورة التعليمية"
                      : "Buy this course"}
                  </button>
                )}
                <ul>
                  <li>
                    <span>
                      <FontAwesomeIcon className="icon" icon={faClock} />
                      {language === "ar" ? "المدة" : "Duration"}
                    </span>
                    <span>{duration}</span>
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon className="icon" icon={faBookOpen} />
                      {language === "ar" ? "المحاضرات" : "Lessons"}{" "}
                    </span>
                    <span>{lessons_number}</span>
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon className="icon" icon={faLanguage} />
                      {language === "ar" ? "الفئة" : "Category"}
                    </span>
                    <span>{categories[0]?.title || ""}</span>
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon className="icon" icon={faCertificate} />
                      {language === "ar"
                        ? "وجود شهادة"
                        : "Certificate Available"}
                    </span>
                    <span>{language === "ar" ? "نعم" : "Yes"}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSuccessMessage && (
        <Alert
          style={{
            position: "absolute",
            top: "100px",
            right: 0,
            left: 0,
          }}
          variant="success"
          onClose={() => setShowSuccessMessage(false)}
          dismissible
        >
          <Alert.Heading>
            {language === "ar"
              ? "تم إرسال الكوبون بنجاح!"
              : "Coupon submitted successfully!"}
          </Alert.Heading>
          <p>
            {language === "ar"
              ? "لقد تم تطبيق كود الكوبون بنجاح. شكرًا لك على استخدامك."
              : "The coupon code has been successfully applied. Thank you for using it."}
          </p>
        </Alert>
      )}

      <Modal show={showCouponModal} onHide={() => setShowCouponModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {language === "ar" ? "أدخل الكوبون" : "Enter Coupon"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder={
              language === "ar" ? "أدخل كود الكوبون" : "Enter coupon code"
            }
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowCouponModal(false)}>
            {language === "ar" ? "إلغاء" : "Cancel"}
          </Button>
          <Button
            style={{ backgroundColor: "#2f2d52", border: "1px solid #2f2d52" }}
            onClick={handleSubmit(onSubmit)}
          >
            {language === "ar" ? "تأكيد" : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CourseDetails;
