import React, { useEffect, useState } from "react";
import axios from "axios";
import "./FeatureSection.scss";
import { useLanguage } from "../../contexts/LanguageContext";
import icon1 from "../../images/ebook.png";
import icon2 from "../../images/ring.png";
import icon3 from "../../images/book.png";

const FeatureSection = () => {
  const [features, setFeatures] = useState([]);
  const { language } = useLanguage();
  const apiUrl = `https://admin.internationalskillsacademy.com/api/why_us/?lang=${language}`;

  const content = {
    ar: {
      header: "لماذا تدرس مع ISA",
      subHeader: "رؤية مختلفة لتطوير الذات و المجتمع.",
    },
    en: {
      header: "Why Study with ISA",
      subHeader: "A different vision for self and community development.",
    },
  };

  const currentContent = content[language];

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await axios.get(apiUrl);
        const featureList = Array.isArray(response.data.results)
          ? response.data.results
          : [response.data];
        setFeatures(featureList);
      } catch (error) {
        console.error("Error fetching data from API", error);
      }
    };

    fetchFeatures();
  }, [apiUrl]);

  return (
    <div className="feature-section">
      <div className="overlay"></div>
      <div className="container">
        <div className="header-text text-center">
          <h3>{currentContent.header}</h3>
          <p>{currentContent.subHeader}</p>
        </div>
        <div className="row">
          {features.map((feature) => (
            <div
              style={{ justifyContent: "center" }}
              className="col-md-4"
              key={feature.id}
            >
              <div className="feature-box">
                <div className="icon">
                  <img src={feature.image} alt={feature.title} />
                </div>
                <h5>{feature.title}</h5>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
