import React, { useState, useEffect } from "react";
import "./JoinUs.scss";
import joinUsImage from "../../images/انضم إلينا.jpg";
import { useForm } from "react-hook-form";
import { useLanguage } from "../../contexts/LanguageContext";

const JoinUs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { language } = useLanguage();

  const content = {
    ar: {
      successMessage: "تم إرسال طلبك بنجاح!",
      fullName: "الاسم بالكامل :",
      email: "البريد الالكتروني :",
      mobile: "رقم الموبايل/الواتساب :",
      additionalMobile: "رقم موبايل اضافي :",
      gender: "مدرب أم مدربة :",
      birthdate: "تاريخ الميلاد :",
      country: "الدولة :",
      degree: "الدرجة العلمية :",
      currentJob: "الوظيفة الحالية :",
      trainingHours: "عدد الساعات التدريبية التي قمت بتنفيذها :",
      experienceYears: "سنوات الخبرة في مجالك :",
      specialties: "التخصصات التي تقدم فيها التدريب :",
      facebookLink: "رابط صفحة الفيسبوك :",
      linkedinLink: "رابط صفحة لينكد إن :",
      cv: "السيرة الذاتية :",
      photo: "صورة شخصية :",
      additionalInfo:
        "معلومات اضافية (جوائز، لقاءات تلفزيونية، مؤتمرات، ...) :",
      submit: "أرسل طلبك",
      introduction:
        "إن أكاديمية ISA للتدريب يشرفها دائما أن تتعاون مع أقوى المحاضرين...",
    },
    en: {
      successMessage: "Your request has been sent successfully!",
      fullName: "Full Name:",
      email: "Email:",
      mobile: "Mobile/WhatsApp Number:",
      additionalMobile: "Additional Mobile Number:",
      gender: "Trainer or Trainer:",
      birthdate: "Birthdate:",
      country: "Country:",
      degree: "Degree:",
      currentJob: "Current Job:",
      trainingHours: "Number of Training Hours Completed:",
      experienceYears: "Years of Experience in Your Field:",
      specialties: "Specialties You Train In:",
      facebookLink: "Facebook Page Link:",
      linkedinLink: "LinkedIn Page Link:",
      cv: "CV:",
      photo: "Profile Picture:",
      additionalInfo:
        "Additional Information (Awards, TV Appearances, Conferences, ...):",
      submit: "Submit Your Request",
      introduction:
        "ISA Academy is always honored to collaborate with the strongest lecturers...",
    },
  };

  const currentContent = content[language];

  const [successMessage, setSuccessMessage] = useState("");
  const [joinImage, setJoinImage] = useState("");

  useEffect(() => {
    const fetchJoinImage = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/website_settings/`
        );
        if (response.ok) {
          const data = await response.json();
          const joinImageUrl = data.results[0].join_image;
          if (joinImageUrl) {
            setJoinImage(joinImageUrl);
          } else {
            console.error("لا توجد صورة للانضمام");
          }
        }
      } catch (error) {
        console.error("خطأ في جلب البيانات:", error);
      }
    };

    fetchJoinImage();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("phone2", data.phone2);
    formData.append("gender", data.gender);
    formData.append("birthdate", data.birth_date);
    formData.append("country", data.country);
    formData.append("degree", data.degree);
    formData.append("currentJob", data.currentJob);
    formData.append("trainingHours", data.trainingHours);
    formData.append("experienceYears", data.experienceYears);
    formData.append("specialties", data.specialties);
    formData.append("facebookLink", data.facebookLink);
    formData.append("linkedinLink", data.linkedinLink);
    formData.append("CV", data.cv[0]);
    formData.append("photo", data.photo[0]);
    formData.append("additionalInfo", data.additionalInfo);

    try {
      const response = await fetch(
        `https://admin.internationalskillsacademy.com/api/join/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);

        setSuccessMessage(currentContent.successMessage);
        reset();
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="join-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-5 mb-lg-0 pe-lg-5">
            {joinImage && (
              <img className="img-fluid" src={joinImage} alt="انضم إلينا" />
            )}
            <p>{currentContent.introduction}</p>
            {/* Additional content and instructions can be added here */}
          </div>
          <div className="col-lg-6 ps-lg-5">
            <div className="px-lg-3">
              <h3>{currentContent.submit}</h3>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-1">
                <div className="row">
                  <div className="form-group col-12">
                    <label>{currentContent.fullName}</label>
                    <input
                      className="form-control"
                      {...register("name", { required: true })}
                      placeholder={language === "ar" ? "اسمك" : "Your Name"}
                    />
                    {errors.name && (
                      <span className="error">
                        {language === "ar" ? "الاسم مطلوب" : "Name is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.email}</label>
                    <input
                      className="form-control"
                      {...register("email", { required: true })}
                      placeholder={language === "ar" ? "ايميلك" : "Your Email"}
                    />
                    {errors.email && (
                      <span className="error">
                        {language === "ar"
                          ? "الايميل مطلوب"
                          : "Email is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.mobile}</label>
                    <input
                      className="form-control"
                      {...register("phone", { required: true })}
                      placeholder={
                        language === "ar"
                          ? "رقم الموبايل/الواتساب"
                          : "Mobile/WhatsApp Number"
                      }
                    />
                    {errors.phone && (
                      <span className="error">
                        {language === "ar"
                          ? "رقم الموبايل مطلوب"
                          : "Mobile number is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.additionalMobile}</label>
                    <input
                      className="form-control"
                      {...register("phone2")}
                      placeholder={
                        language === "ar"
                          ? "رقم موبايل اضافي"
                          : "Additional Mobile Number"
                      }
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.gender}</label>
                    <select
                      className="form-control"
                      {...register("gender", { required: true })}
                    >
                      <option value="">
                        {language === "ar" ? "اختر..." : "Select..."}
                      </option>
                      <option value="male">
                        {language === "ar" ? "مدرب" : "Trainer"}
                      </option>
                      <option value="female">
                        {language === "ar" ? "مدربة" : "Trainer"}
                      </option>
                    </select>
                    {errors.gender && (
                      <span className="error">
                        {language === "ar"
                          ? "يجب اختيار الجنس"
                          : "Gender is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.birthdate}</label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("birthdate", { required: true })}
                    />
                    {errors.birthdate && (
                      <span className="error">
                        {language === "ar"
                          ? "تاريخ الميلاد مطلوب"
                          : "Birthdate is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.country}</label>
                    <input
                      className="form-control"
                      {...register("country", { required: true })}
                      placeholder={language === "ar" ? "الدولة" : "Country"}
                    />
                    {errors.country && (
                      <span className="error">
                        {language === "ar"
                          ? "الدولة مطلوبة"
                          : "Country is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.degree}</label>
                    <input
                      className="form-control"
                      {...register("degree", { required: true })}
                      placeholder={
                        language === "ar" ? "الدرجة العلمية" : "Degree"
                      }
                    />
                    {errors.degree && (
                      <span className="error">
                        {language === "ar"
                          ? "الدرجة العلمية مطلوبة"
                          : "Degree is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.currentJob}</label>
                    <input
                      className="form-control"
                      {...register("currentJob", { required: true })}
                      placeholder={
                        language === "ar" ? "الوظيفة الحالية" : "Current Job"
                      }
                    />
                    {errors.currentJob && (
                      <span className="error">
                        {language === "ar"
                          ? "الوظيفة الحالية مطلوبة"
                          : "Current job is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.trainingHours}</label>
                    <input
                      type="number"
                      className="form-control"
                      {...register("trainingHours", { required: true })}
                      placeholder={
                        language === "ar"
                          ? "عدد الساعات التدريبة"
                          : "Number of Training Hours"
                      }
                    />
                    {errors.trainingHours && (
                      <span className="error">
                        {language === "ar"
                          ? "عدد الساعات التدريبية المطلوبة"
                          : "Training hours are required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.experienceYears}</label>
                    <input
                      type="number"
                      className="form-control"
                      {...register("experienceYears", { required: true })}
                      placeholder={
                        language === "ar"
                          ? "سنوات الخبرة"
                          : "Years of Experience"
                      }
                    />
                    {errors.experienceYears && (
                      <span className="error">
                        {language === "ar"
                          ? "سنوات الخبرة مطلوبة"
                          : "Years of experience are required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.specialties}</label>
                    <input
                      className="form-control"
                      {...register("specialties", { required: true })}
                      placeholder={
                        language === "ar" ? "التخصصات" : "Specialties"
                      }
                    />
                    {errors.specialties && (
                      <span className="error">
                        {language === "ar"
                          ? "التخصصات مطلوبة"
                          : "Specialties are required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.facebookLink}</label>
                    <input
                      className="form-control"
                      {...register("facebookLink")}
                      placeholder={
                        language === "ar"
                          ? "رابط صفحة الفيسبوك"
                          : "Facebook Page Link"
                      }
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.linkedinLink}</label>
                    <input
                      className="form-control"
                      {...register("linkedinLink")}
                      placeholder={
                        language === "ar"
                          ? "رابط صفحة لينكد إن"
                          : "LinkedIn Page Link"
                      }
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.cv}</label>
                    <input
                      type="file"
                      className="form-control"
                      {...register("cv", { required: true })}
                    />
                    {errors.cv && (
                      <span className="error">
                        {language === "ar"
                          ? "السيرة الذاتية مطلوبة"
                          : "CV is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.photo}</label>
                    <input
                      type="file"
                      className="form-control"
                      {...register("photo", { required: true })}
                    />
                    {errors.photo && (
                      <span className="error">
                        {language === "ar"
                          ? "الصورة الشخصية مطلوبة"
                          : "Profile picture is required"}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>{currentContent.additionalInfo}</label>
                    <textarea
                      className="form-control"
                      {...register("additionalInfo")}
                      placeholder={
                        language === "ar"
                          ? "معلومات اضافية"
                          : "Additional Information"
                      }
                    />
                  </div>
                </div>
                <button type="submit" className="btn-black">
                  {currentContent.submit}
                </button>
                {successMessage && (
                  <div className="success-message">{successMessage}</div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
