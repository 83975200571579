import Regulations from "../Regulations/Regulations";
import "./Rules.scss";
import { useLanguage } from "../../contexts/LanguageContext";

export default function Rules() {
  const { language } = useLanguage();

  const content = {
    ar: {
      title: "القواعد التنظيمية لحضور الدورات والبرامج التدريبية",
      groupRules: {
        title: "القواعد التنظيمية الخاصة بالمجموعات التدريبية:",
        rules: [
          "التفاعل مع المحاضر في المناقشات والاسئلة والاجابة شرط هام من شروط احتساب الحضور او الغياب وعدم تفاعلك يعني أنك غير موجود داخل المحاضرة.",
          "يشترط لإصدار الشهادة التدريبية ألا تقل نسبة الحضور عن 70% من اجمالي عدد الساعات التدريبية في البرنامج.",
          "المحاضرات المسجلة يمكنك مشاهدتها والاستفادة منها في مراجعة المحاضرة لكنها لا تؤثر على نسبة الحضور إذ أن الحضور يتم احتسابه في المحاضرات المباشرة Live فقط.",
          "في الدورات التي تشمل مشروعات يجب على المتدرب الالتزام بتقديم المشروع في الوقت الذي يحدده المحاضر.",
          "جدول المحاضرات الذي تم اقراره غير قابل للتعديل طوال فترة البرنامج الا في الظروف القهرية وبموافقة ادارة الاكاديمية ويشترط للتعديل موافقة كافة المتدربين في المجموعة على هذا التعديل وان يكون هذا التعديل مقبولاً من جانب المحاضر ولا يتعارض مع جداول أخرى.",
          "يمكن للمتدرب مراجعة بياناته من خلال صفحته ويمكنه اجراء تعديل على الاسم بالصورة الصحيحة التي يرغب في ظهورها في الشهادة التدريبية.",
        ],
      },
      privateRules: {
        title: "القواعد التنظيمية الخاصة بالدورات البرايفت:",
        rules: [
          "التفاعل مع المحاضر في المناقشات والاسئلة والاجابة شرط هام من شروط احتساب الحضور او الغياب وعدم تفاعلك يعني أنك غير موجود داخل المحاضرة.",
          "يشترط لإصدار الشهادة التدريبية ألا تقل نسبة الحضور عن 70% من اجمالي عدد الساعات التدريبية في البرنامج.",
          "المحاضرات المسجلة يمكنك مشاهدتها والاستفادة منها في مراجعة المحاضرة لكنها لا تؤثر على نسبة الحضور إذ أن الحضور يتم احتسابه في المحاضرات المباشرة Live فقط.",
          "يمكنك تعديل مواعيد محاضرة أو إلغاؤها أو ترحيلها بشرطين:\n- أن يتم الابلاغ قبل موعد المحاضرة بمدة لا تقل عن 24 ساعة.\n- أن يكون هذا التعديل مقبولا من جانب المحاضر ولا يتعارض مع جداول أخرى.",
          "في حالة عدم حضور المتدرب للمحاضرة حسب الجدول دون إخطار مسبق فلا يتم تعويض المحاضرة ويتم احتساب المحاضرة على المتدرب (غياب) ويعاد توزيع المادة التدريبية على المحاضرات المتبقية.",
          "في الدورات التي تشمل مشروعات يجب على المتدرب الالتزام بتقديم المشروع في الوقت الذي يحدده المحاضر.",
          "يمكن للمتدرب مراجعة بياناته من خلال صفحته ويمكنه اجراء تعديل على الاسم بالصورة الصحيحة التي يرغب في ظهورها في الشهادة التدريبية.",
          "المحاضرات التدريبية تتم وفقاً للمواعيد المقررة في الجدول التدريبي ولا يسمح للمتدرب التأخر عن موعد المحاضرة بمدة تتجاوز 30 دقيقة بدون اخطار المنسق أو 60 دقيقة بإخطار منسق البرنامج علماً بأن المحاضرة ستنتهي في وقتها المحدد ولا يتم تعويض الوقت المستقطع في نهاية المحاضرة.",
        ],
      },
      companyRules: {
        title: "القواعد التنظيمية لحضور الدورات التدريبية لموظفي الشركات:",
        rules: [
          "يجب تسليم بيانات المجموعة التدريبية من طرف مسؤول التدريب الى قسم التنسيق بالأكاديمية قبل بداية البرنامج بمدة كافية لإدخال بياناتهم وانشاء الحسابات واتمام انضمام الموظفين للبرنامج التدريبي.",
          "استيفاء نسبة حضور 70% للموظف هي إلزامية لاستصدار الشهادة التدريبية الخاصة بهذا الموظف، وإلا لن يتم اصدار شهادة تدريبية للموظف الذي لم يصل الى تلك النسبة.",
          "يحق لمسؤول التدريب الاطلاع على كشف الحضور والغياب الخاص بالمجموعة.",
        ],
      },
    },
    en: {
      title: "Organizational Rules for Attending Courses and Training Programs",
      groupRules: {
        title: "Organizational Rules for Training Groups:",
        rules: [
          "Interaction with the lecturer in discussions, questions, and answers is an important condition for calculating attendance or absence. Your lack of interaction means you are not present in the lecture.",
          "To issue the training certificate, the attendance rate must not be less than 70% of the total number of training hours in the program.",
          "You can watch and benefit from recorded lectures in reviewing the lecture, but they do not affect the attendance rate as attendance is calculated only in live lectures.",
          "In courses that include projects, the trainee must commit to submitting the project at the time specified by the lecturer.",
          "The approved lecture schedule is not subject to modification throughout the program period except in force majeure circumstances and with the approval of the academy administration. Modification requires the approval of all trainees in the group, and this modification must be acceptable to the lecturer and not conflict with other schedules.",
          "The trainee can review their data through their page and can make changes to the name in the correct form that they want to appear on the training certificate.",
        ],
      },
      privateRules: {
        title: "Organizational Rules for Private Courses:",
        rules: [
          "Interaction with the lecturer in discussions, questions, and answers is an important condition for calculating attendance or absence. Your lack of interaction means you are not present in the lecture.",
          "To issue the training certificate, the attendance rate must not be less than 70% of the total number of training hours in the program.",
          "You can watch and benefit from recorded lectures in reviewing the lecture, but they do not affect the attendance rate as attendance is calculated only in live lectures.",
          "You can modify, cancel, or postpone lecture appointments under two conditions:\n- Notification must be made at least 24 hours before the lecture time.\n- This modification must be acceptable to the lecturer and not conflict with other schedules.",
          "If the trainee does not attend the lecture according to the schedule without prior notice, the lecture will not be compensated and the lecture will be counted as absence for the trainee, and the training material will be redistributed to the remaining lectures.",
          "In courses that include projects, the trainee must commit to submitting the project at the time specified by the lecturer.",
          "The trainee can review their data through their page and can make changes to the name in the correct form that they want to appear on the training certificate.",
          "Training lectures are conducted according to the scheduled times in the training schedule. The trainee is not allowed to be late for the lecture for more than 30 minutes without notifying the coordinator or 60 minutes with notification to the program coordinator. Note that the lecture will end at its scheduled time and the deducted time will not be compensated at the end of the lecture.",
        ],
      },
      companyRules: {
        title:
          "Organizational Rules for Attending Training Courses for Company Employees:",
        rules: [
          "The training group data must be submitted by the training manager to the coordination department of the academy well before the start of the program to enter their data, create accounts, and complete the employees' joining of the training program.",
          "Fulfilling a 70% attendance rate for the employee is mandatory to issue the training certificate for this employee. Otherwise, a training certificate will not be issued for the employee who did not reach that percentage.",
          "The training manager has the right to view the attendance and absence record of the group.",
        ],
      },
    },
  };

  const currentContent = content[language];

  return (
    <div className="rules" style={{ paddingTop: "220px", padding: "50px" }}>
      <div className="overlay"></div>
      <h2>{currentContent.title}</h2>

      <h4 style={{ lineHeight: "2", marginTop: "50px" }}>
        {currentContent.groupRules.title}
      </h4>
      <ul style={{ lineHeight: "2" }}>
        {currentContent.groupRules.rules.map((rule, index) => (
          <li key={index}>{rule}</li>
        ))}
      </ul>

      <h4 style={{ lineHeight: "2", marginTop: "50px" }}>
        {currentContent.privateRules.title}
      </h4>
      <ul style={{ lineHeight: "2" }}>
        {currentContent.privateRules.rules.map((rule, index) => (
          <li key={index}>{rule}</li>
        ))}
      </ul>

      <h4 style={{ lineHeight: "2", marginTop: "50px" }}>
        {currentContent.companyRules.title}
      </h4>
      <ul style={{ lineHeight: "2" }}>
        {currentContent.companyRules.rules.map((rule, index) => (
          <li key={index}>{rule}</li>
        ))}
      </ul>

      <Regulations />
    </div>
  );
}
