import { Link } from "react-router-dom";
import "./Privacy.scss";
import { useLanguage } from "../../contexts/LanguageContext";

export default function Privacy() {
  const { language } = useLanguage();

  const content = {
    ar: {
      title: "سياسة الخصوصية",
      intro:
        "يأخذ موقع أكاديمية ISA للتدريب خصوصيتك على محمل الجد.. لذا يرجى قراءة ما يلي لمعرفة المزيد حول سياسة الخصوصية التي يتبعها الموقع.",
      personalInfoUsage: "كيف يستخدم أكاديمية ISA للتدريب معلوماتك الشخصية",
      usageDescription:
        "يوضح المعلومات التالية كيفية تعامل موقع أكاديمية ISA للتدريب مع المعلومات الشخصية التي يجمعها و /أو يتلقاها، بما في ذلك المعلومات المتعلقة باستخدامك السابق لأي من خدمات ومحتويات أكاديمية ISA للتدريب.",
      personalInfoDefinition:
        "المعلومات الشخصية هي معلومات عنك تحدد هويتك مثل اسمك وعنوانك وعنوان البريد الإلكتروني، أو رقم الهاتف.",
      privacyPolicyActivation:
        "كيفية تفعيل سياسة الخصوصية على أكاديمية ISA للتدريب",
      activationDescription:
        "هذه السياسة لا تنطبق على تعاملات أو ممارسات مواقع أو قنوات لا يملكها أو يسيطر عليها أكاديمية ISA للتدريب، وكذا على الموظفين أو الأشخاص الذين لا يعملون في أكاديمية ISA للتدريب أو تحت إدارته.",
      protectionRights: "حماية حقوق الطبع والملكية الفكرية",
      protectionDescription:
        "أكاديمية ISA للتدريب يشارك في حماية حقوق الطبع والملكية الفكرية سواء لمؤسسات أو لأفراد وذلك بعدم نشر أي محتوى أو خدمة إلا مع ذكر اسم المصدر أو بالرجوع لصاحب حقوق الطبع أو الملكية لهذا المحتوى أو الخدمة.",
      collectionUsage: "جمع المعلومات واستخدامها",
      collectionDescription:
        "يقوم موقع أكاديمية ISA للتدريب بجمع المعلومات الشخصية عندما تقوم بالتسجيل لإنشاء حساب على أكاديمية ISA للتدريب، وعند التسجيل في بعض الخدمات.",
      requiredInfo: "المعلومات التي نطلبها تشمل:",
      infoList: [
        "اسمك",
        "عنوان البريد الإلكتروني",
        "تاريخ الميلاد",
        "الجنس",
        "الرمز البريدي",
        "الوظيفة",
        "محل الإقامة",
      ],
      children: "أطفال",
      childrenDescription:
        "غير مسموح لمن هم دون سن الـعاشرة بالتسجيل على أكاديمية ISA للتدريب.",
      sharingInfo: "مشاركة وكشف المعلومات",
      sharingDescription:
        "لا يقوم أكاديمية ISA للتدريب بإيجار أو بيع أو مشاركة معلوماتك الشخصية مع أشخاص آخرين أو شركات غير تابعة إلا لتوفير محتويات أو الخدمات التي طلبتها.",
      cookies: "الكوكيز",
      cookiesDescription:
        "أكاديمية ISA للتدريب قد يخزن ويحصل على الكوكيز من جهاز الكمبيوتر الخاص بك.",
      modifyDeleteInfo: "قدرتك على تعديل وحذف معلومات حسابك وتفضيلاتك",
      modifyDescription:
        "يمكنك تعديل معلومات حساب أكاديمية ISA للتدريب الخاص بك، بما في ذلك تفضيلاتك التسويقية في أي وقت.",
      confidentiality: "السرية والأمان",
      confidentialityDescription:
        "يحظر أكاديمية ISA للتدريب الحصول على معلومات شخصية عنك إلا للموظفين الذين نعتقد أنهم بحاجة إليها لتوفير الخدمات لك.",
      modifications: "التعديلات قد تطرأ على سياسة الخصوصية",
      modificationsDescription:
        "قد يقوم موقع أكاديمية ISA للتدريب بتحديث هذه السياسة. سيتم إعلامك بالتغييرات الكبيرة عن طريق الإشعار على البريد الإلكتروني أو وضع إشعار بارز على الموقع.",
      questionsSuggestions: "أسئلة و اقتراحات",
      contact:
        "إذا كان لديك أسئلة أو اقتراحات، يرجى ملء نموذج ملاحظاتك أو اقتراحاتك أو استفساراتك",
      contactLink: "بالضغط هنا.",
      address:
        "يمكنك مراسلتنا على العنوان التالي: المنشية، خلف بناء المحافظة/ طرطوس، الجمهورية العربية السورية.",
      email:
        "يمكنك الاتصال بنا عبر البريد الإلكتروني: isaacademy023@gmail.com أو عن طريق الهاتف: ",
    },
    en: {
      title: "Privacy Policy",
      intro:
        "ISA Academy takes your privacy seriously. Please read the following to learn more about the privacy policy followed by the site.",
      personalInfoUsage: "How ISA Academy Uses Your Personal Information",
      usageDescription:
        "The following information outlines how ISA Academy handles personal information that it collects and/or receives, including information regarding your previous use of any of ISA Academy's services and content.",
      personalInfoDefinition:
        "Personal information is information about you that identifies you, such as your name, address, email address, or phone number.",
      privacyPolicyActivation:
        "How to Activate the Privacy Policy at ISA Academy",
      activationDescription:
        "This policy does not apply to transactions or practices of websites or channels that are not owned or controlled by ISA Academy, nor to employees or individuals who do not work for ISA Academy or under its management.",
      protectionRights:
        "Protection of Copyright and Intellectual Property Rights",
      protectionDescription:
        "ISA Academy participates in protecting copyright and intellectual property rights for both institutions and individuals by not publishing any content or service without mentioning the source or referring to the copyright or ownership of that content or service.",
      collectionUsage: "Collecting and Using Information",
      collectionDescription:
        "ISA Academy collects personal information when you register to create an account on ISA Academy and when you register for certain services.",
      requiredInfo: "The information we request includes:",
      infoList: [
        "Your Name",
        "Email Address",
        "Date of Birth",
        "Gender",
        "Postal Code",
        "Occupation",
        "Place of Residence",
      ],
      children: "Children",
      childrenDescription:
        "Children under the age of ten are not allowed to register at ISA Academy.",
      sharingInfo: "Sharing and Disclosure of Information",
      sharingDescription:
        "ISA Academy does not rent, sell, or share your personal information with others or non-affiliated companies except to provide the content or services you requested.",
      cookies: "Cookies",
      cookiesDescription:
        "ISA Academy may store and access cookies from your computer.",
      modifyDeleteInfo:
        "Your Ability to Modify and Delete Your Account Information and Preferences",
      modifyDescription:
        "You can modify your ISA Academy account information, including your marketing preferences, at any time.",
      confidentiality: "Confidentiality and Security",
      confidentialityDescription:
        "ISA Academy prohibits obtaining personal information about you except for employees who we believe need it to provide services to you.",
      modifications: "Modifications to the Privacy Policy",
      modificationsDescription:
        "ISA Academy may update this policy. You will be notified of significant changes by email notification or by placing a prominent notice on the site.",
      questionsSuggestions: "Questions and Suggestions",
      contact:
        "If you have any questions or suggestions, please fill out your feedback or inquiries",
      contactLink: "by clicking here.",
      address:
        "You can contact us at the following address: Al Manshiyah, behind the Governorate Building / Tartous, Syrian Arab Republic.",
      email: "You can reach us by email: isaacademy023@gmail.com or by phone: ",
    },
  };

  const currentContent = content[language];

  return (
    <div className="privacy" style={{ paddingTop: "220px", padding: "50px" }}>
      <div className="overlay"></div>
      <h4 style={{ marginTop: "unset" }}>{currentContent.title}</h4>
      <p>{currentContent.intro}</p>

      <h4>{currentContent.personalInfoUsage}</h4>
      <p>
        {currentContent.usageDescription}
        <br />
        {currentContent.personalInfoDefinition}
      </p>

      <h4>{currentContent.privacyPolicyActivation}</h4>
      <p>{currentContent.activationDescription}</p>

      <h4>{currentContent.protectionRights}</h4>
      <p>{currentContent.protectionDescription}</p>

      <h4>{currentContent.collectionUsage}</h4>
      <p>
        {currentContent.collectionDescription}
        <br />
        {currentContent.requiredInfo}
      </p>
      <ul>
        {currentContent.infoList.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p>
        {currentContent.children}
        <br />
        {currentContent.childrenDescription}
      </p>

      <h4>{currentContent.sharingInfo}</h4>
      <p>{currentContent.sharingDescription}</p>

      <h4>{currentContent.cookies}</h4>
      <p>{currentContent.cookiesDescription}</p>

      <h4>{currentContent.modifyDeleteInfo}</h4>
      <p>{currentContent.modifyDescription}</p>

      <h4>{currentContent.confidentiality}</h4>
      <p>{currentContent.confidentialityDescription}</p>

      <h4>{currentContent.modifications}</h4>
      <p>{currentContent.modificationsDescription}</p>

      <h4>{currentContent.questionsSuggestions}</h4>
      <p>
        {currentContent.contact}{" "}
        <Link to="/contact">{currentContent.contactLink}</Link>
        <br />
        {currentContent.address}
        <br />
        {currentContent.email}{" "}
        <a href="mailto:isaacademy023@gmail.com">isaacademy023@gmail.com</a> أو
        عن طريق الهاتف:
        <a href="tel:+963988954443">+ 963 988 954 443</a>
      </p>
    </div>
  );
}
